import React from 'react'
import { observer } from 'mobx-react'
import BaseLogin from 'spider/semantic-ui/Login'
import { Body, ContentContainer } from 're-cy-cle'
import { Divider, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Sidebar as BaseSidebar, SidebarContent } from 'spider/semantic-ui/Admin/Overview'
import TrippyBackground from 'component/TrippyBackground'
import Logo from 'image/logo.svg'

const Sidebar = styled(BaseSidebar)`
  background-color: #fff;
  ${SidebarContent} {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
`

const BlockLink = styled(Link)`
    display: block;
    margin: 1.5em auto;
`;

const HeaderImg = styled.img`
    height: 3em;
`;

export function sidebarTheme(Screen) {
    return observer(class extends Screen {
        render() {
            return (
                <Body>
                    <ContentContainer>
                        <TrippyBackground>
                        </TrippyBackground>
                        <Sidebar>
                            <BlockLink to="/">
                                <HeaderImg src={Logo} />
                            </BlockLink>
                            {this.renderContent()}
                            <div style={{ flex: '1' }} />
                            {this.renderBranding()}
                        </Sidebar>
                    </ContentContainer>
                </Body>
            );
        }
    });
}

@sidebarTheme
export default class Login extends BaseLogin {
  renderForm() {
    return (
      <React.Fragment>
        {super.renderForm()}
        <Divider horizontal>{t('user.login.or')}</Divider>
        <Button
          fluid
          secondary
          data-test-work-station-login-button
          content={t('user.login.workStationStartButton')}
          as={Link}
          to="/production/login"
        />
        <Divider horizontal>{t('user.login.or')}</Divider>
        <Button
          fluid
          secondary
          data-test-operator-login-button
          content={t('user.login.operatorStartButton')}
          as={Link}
          to="/operator/login"
        />
      </React.Fragment>
    )
  }
}
