export default {
  pageNotFoundMessage: 'Website niet gevonden, is de URL juist?',
  brandingMessage: 'by Code Yellow',
  nav: {
    main: {
      production: 'Productie',
      warehouse: 'Magazijn',
      planning: 'Planning',
      personnel: 'Personeel',
      assets: 'Stamgegevens',
      plantation: 'Plantage',
    },
    production: {
      progress: 'Voortgang',
      batches: 'Producten',
      products: 'Producten',
      productionRequests: 'Werkorder',
      workStationSessions: 'Werkplek Sessies',
    },
    planning: {
      productionLines: 'Productie omgeving',
      productionRequests: 'Werkorder',
      rampUps: 'Scenario Tool',
      workStations: 'Dagplanning',
      day: 'Dagoverzicht',
    },
    warehouse: {
      stock: 'Voorraad',
      inbound: 'Inbound',
      outbound: 'Outbound',
      purchaseOrder: 'Inkoop order',
      warehouseTransfer: 'Magazijnverplaatsingen',
    },
    personnel: {
      users: 'Gebruikers',
      operators: 'Operators',
      leaveCalendars: 'Verlofkalenders',
      leaveSlots: 'Verlofaanvragen',
    },
    assets: {
      processes: 'Processen',
      articleTypes: 'Artikeltypes',
      exactItems: 'Exact Artikelen',
      productionLines: 'Productielijnen',
      capabilities: 'Vaardigheden',
      processPhases: 'Proces Fases',
      dataSources: 'Databronnen',
      loadCarriers: 'Ladingdragers',
      processPhases: 'Proces Fasen',
      dataSources: 'Data Bronnen',
      globalValues: 'Instellingen',
      globalFiles: 'Bijzondere bestanden',
      templates: 'Templates',
      integrations: 'Integraties',
      projects: 'Projecten',
      factories: 'Fabrieken',
    },
    plantation: {
      scripts: 'Scripts',
      plantationLines: 'Plantageomgevingen',
      units: 'Units',
    },
    account: {
      clockInTime: 'Ingeklokt om {{clockInTime}}',
      notClockedIn: 'Niet ingeklokt',
      clockIn: 'Inklokken',
      clockOut: 'Uitklokken',
      profile: 'Profiel',
      logout: 'Uitloggen',
      changelog: 'Changelog',
      clockOutModal: {
        title: 'Je bent nog ingeklokt.',
        message: 'Wil je naast uitloggen ook uitklokken?',
      },
    },
  },
  unit: {
    overview: {
      title: 'Units'
    },
    field: {
      name: {
        label: 'Naam'
      }
    }
  },
  profile: {
    title: 'Profiel',
    changePassword: {
      label: 'Wachtwoord Veranderen',
      passwordOld: 'Huidig Wachtwoord',
      passwordNew: 'Nieuw Wachtwoord',
    },
  },
  user: {
    overview: {
      title: 'Gebruikers',
      empty: 'Er zijn geen gebruikers die voldoen aan uw zoekopdracht.',
      addButton: 'Gebruiker',
      convertToOperator: 'Converteren naar operator',
    },
    create: {
      title: '{{fullName}} Aanmaken',
    },
    edit: {
      title: '{{fullName}} Aanpassen',
      saveBeforeLeaveSlot: 'Je moet de gebruiker eerst opslaan voordat je verlof toe kan voegen.',
      tab: {
        info: 'Gegevens',
        availability: 'Beschikbaarheid',
      },
    },
    field: {
      leaveBalances: { label: 'Verlofoverzicht' },
      avatar: { label: 'Profielfoto' },
      fullName: { label: 'Naam', unknown: 'Gebruiker' },
      firstName: { label: 'Voornaam' },
      lastName: { label: 'Achternaam' },
      email: { label: 'E-mail' },
      password: { label: 'Wachtwoord' },
      language: { label: 'Taal' },
      dateJoined: { label: 'Toegevoegd Op' },
      manager: { label: 'Manager' },
      language: { label: 'Taal' },
      badgeId: { label: 'Badge ID' },
      groups: {
        label: 'Groepen',
        value: {
          superuser: 'Supergebruiker',
          admin: 'Admin',
          manager: 'Manager',
        },
        empty: 'Geen groepen toegekend',
      },
      availability: { label: 'Beschikbaarheid' },
      workSchedules: { label: 'Roosters' },
      leaveCalendars: { label: 'Verlofkalenders' },
      extraLeaveBalances: {
        label: 'Resterend saldo',
        empty: 'Er is geen resterend saldo ingevoerd.',
      },
      isOperator: { label: 'Is Operator' },
    },
    login: {
      title: 'Inloggen',
      reloginMessage: 'U bent uitgelogd. Log opnieuw in.',
      forgotPasswordLink: 'Wachtwoord vergeten?',
      errors: {
        invalidCredentials: 'E-mail of wachtwoord is niet correct',
        unknown: 'Onbekende fout, status code: {{status}}',
      },
      loginButton: 'Inloggen',
      workStationStartButton: 'Start Werkplek',
      operatorStartButton: 'Inloggen als Operator',
      or: 'Of',
      backButton: 'Ga terug',
    },
    passwordForgot: {
      title: 'Wachtwoord Vergeten',
      requestButton: 'Verstuur Email',
      requestedEmailText: 'Er is een email met een wachtwoord reset code verzonden naar uw e-mail adres.',
    },
    resetPassword: {
      title: 'Wachtwoord Reset',
      resetButton: 'Reset',
      saveSuccess: 'Uw wachtwoord is succesvol gereset.',
    },
    activate: {
      title: 'Account Activeren',
      intro: 'Welkom bij Tracy! U hoeft alleen nog een wachtwoord in te stellen om uw account te activeren.',
      activateButton: 'Activeer Account',
      saveSuccess: 'Uw account is succesvol geactiveerd.',
    },
    changePassword: {
      title: 'Wachtwoord Wijzigen',
      field: {
        passwordOld: {
          label: 'Huidig wachtwoord',
        },
        passwordNew: {
          label: 'Nieuw wachtwoord',
        },
      },
      saveButton: 'Opslaan',
    },
    account: {
      title: 'Profiel',
      logoutButton: 'Uitloggen',
      info: {
        title: 'Gegevens',
      },
      password: {
        title: 'Wachtwoord Veranderen',
      },
      logout: {
        title: 'Uitloggen',
        confirm: 'Weet u zeker dat u wilt uitloggen?',
      },
    },
  },
  exactItem: {
    overview: {
      title: 'Artikelen',
      empty: 'Er zijn geen artikelen die voldoen aan uw zoekopdracht.',
      viewLink: '(bekijken)',
      actions: {
        label: 'Koppelingen Aanpassen ({{count}})',
        action: {
          linkArticleTypes: 'Koppel Artikeltype ({{count}})',
          linkArticleTypes_plural: 'Koppel Artikeltypes ({{count}})',
          unlinkArticleTypes: 'Ontkoppel Artikeltype ({{count}})',
          unlinkArticleTypes_plural: 'Ontkoppel Artikeltypes ({{count}})',
        },
      },
    },
    edit: {
      title: 'Artikel Aanpassen',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      classification: { label: 'Artikel groep' },
      isMakeItem: { label: 'Maak' },
      isBuyItem: { label: 'Koop' },
      name: { label: 'Naam' },
      articleType: { label: 'Artikeltype' },
      componentType: { label: 'Componenttype' },
    },
  },
  operator: {
    home: {
      welcome: 'Welkom, {{firstName}}!',
      clockInTime: 'Je bent ingeklokt om {{clockInTime}}.',
      notClockedIn: 'Je bent op het moment niet ingeklokt.',
      availability: 'Beschikbaarheid',
      scheduled: {
        notScheduled: 'Je bent vandaag niet ingepland op een werkstation.',
        title: 'Vandaag ben je ingepland op de volgende werkstations:',
        times: '{{startAt}} - {{endAt}}',
      },
    },
    availability: {
      title: 'Beschikbaarheid',
    },
    logoutModal: {
      title: 'Uitloggen',
      content: 'Weet je zeker dat je wilt uitloggen?',
      logoutButton: 'Uitloggen',
    },
    start: {
      title: 'Inloggen als Operator',
      startButton: 'Inloggen',
    },
    overview: {
      title: 'Operators',
      empty: 'Er zijn geen operators die voldoen aan uw zoekopdracht.',
      addButton: 'Operator',
      printBadge: 'Print Badge',
    },
    create: {
      title: '{{fullName}} Aanmaken',
    },
    edit: {
      title: '{{fullName}} Aanpassen',
      saveBeforeLeaveSlot: 'Je moet de operator eerst opslaan voordat je verlof toe kan voegen.',
      tab: {
        info: 'Gegevens',
        capabilities: 'Vaardigheden',
        availability: 'Beschikbaarheid',
      },
      workScheduleDescription: `Rooster vanaf {{startDate}}`,
    },
    field: {
      leaveBalances: { label: 'Verlofoverzicht' },
      avatar: { label: 'Profielfoto' },
      fullName: { label: 'Naam', unknown: 'Operator' },
      firstName: { label: 'Voornaam' },
      lastName: { label: 'Achternaam' },
      operatorCapabilities: {
        label: 'Vaardigheden',
        empty: 'Geen vaardigheden toegekend',
        none: 'Er zijn nog geen vaardigheden geconfigureerd in het systeem.',
      },
      createdAt: { label: 'Toegevoegd Op' },
      badgeId: { label: 'Badge ID' },
      workSchedules: {
        label: 'Roosters',
        empty: 'Er zijn nog geen roosters toegevoegd.',
      },
      availability: { label: 'Beschikbaarheid' },
      leaveCalendars: { label: 'Verlofkalenders' },
      manager: { label: 'Manager' },
      hasPendingLeave: { label: 'Openstaande verlofaanvragen' },
      extraLeaveBalances: {
        label: 'Resterend saldo',
        empty: 'Er is geen resterend saldo toegevoegd.',
      },
      deleted: { label: 'Verwijderd' },
    },
  },
  articleTypeWarehouse: {
    overview: {
      empty: 'Geen voorraad gevonden',
    },
    field: {
      stock: { label: 'Voorraad', },
    },
    articleType: {
      id: 'ID',
      code: 'Code',
      classification: 'Groep',
      name: 'Naam',
    },
    warehouse: {
      name: 'Magazijn',
    },
    storageLocations: {
      field: {
        lines: { label: 'Opslaglocaties' },
        code: { label: 'Code' },
        default: { label: 'Standaard' },
        name: { label: 'Naam' },
        stock: { label: 'Voorraad' },
      },
      noStorageLocation: 'geen opslaglocatie gespecificeerd',
    },
  },
  salesOrder: {
    basicInfo: 'Details',
    overview: {
      empty: 'Er zijn geen sales orders die voldoen aan uw zoekopdracht.',
    },
    confirmDelete: 'Weet u zeker dat u deze sales order wil verwijderen?',
    field: {
      id: { label: 'ID' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          partial: 'Gedeeltelijk',
          complete: 'Gereed',
          canceled: 'Geannuleerd',
        },
      },
      customer: { label: 'Klant' },
      reference: { label: 'Referentie' },
      orderNumberErp: { label: 'Nummer' },
      orderDate: { label: 'Orderdatum' },
      deliveryDate: { label: 'Leverdatum' },
      deliveryAddress: { label: 'Leveradres' },
      remarks: { label: 'Opmerkingen' },
      description: { label: 'Omschrijving' },
      outShipments: {
        label: 'Verzamelorder',
        empty: 'Nog geen verzamelorders toegevoegd.',
        value: 'Verzamelorder {{id}}',
      },
      lines: { label: 'Verkooporder regels' },
      quantity: { label: 'Aantal' },
      exactSalesOrder: { label: 'Verkooporder' },
      warehouse: { label: 'Magazijn' },
    },
    filters: {
      todo: 'Te doen',
      inProgress: 'Bezig',
      complete: 'Klaar',
      overdueReceipts: 'Te laat (zonder werkorders)',
      overdueRequests: 'Te laat (met werkorders)',
      receiptsThisWeek: 'Gepland deze week',
      futureReceipts: 'Gepland toekomst',
    },
  },
  salesOrderLine: {
    addToOutShipmentButton: 'Toevoegen',
    field: {
      id: { label: 'ID' },
      project: { label: 'Project' },
      productionDueDate: { label: 'Productie Einddatum' },
      deliveryDate: { label: 'Leverdatum' },
      quantity: { label: 'Aantal' },
      _toReceive: { label: 'Te ontvangen' },
      unitPrice: { label: 'Stuk Prijs' },
      netPrice: { label: 'Net Prijs' },
      code: { label: 'Artikel Code' },
      salesOrder: { label: 'Verkooporder' },
      erpLines: { label: 'ERP' },
    },
  },
  exactPurchaseOrder: {
    field: {
      id: { label: 'ID' },
      number: { label: 'Nummer' },
      status: {
        label: 'Status',
        value: {
          10: 'Open',
          20: 'Gedeeltelijk',
          30: 'Gereed',
          40: 'Afgebroken',
        },
      },
    },
  },
  exactSalesOrder: {
    field: {
      id: { label: 'ID' },
      number: { label: 'Nummer' },
    },
  },
  purchaseOrder: {
    overview: {
      empty: 'Er zijn geen inkooporders die voldoen aan uw zoekopdracht.',
    },
    filters: {
      status: 'Status',
      requestStatus: 'Werkorder Status',
      ordersDueAndExpected: 'Orders te laat & verwacht',
      number: 'Nummer',
      todo: 'Te doen',
      inProgress: 'Bezig',
      complete: 'Klaar',
      overdueReceipts: 'Te laat (zonder werkorders)',
      overdueRequests: 'Te laat (met werkorders)',
      receiptsThisWeek: 'Gepland deze week',
      futureReceipts: 'Gepland toekomst',
    },
    confirmDelete: 'Weet u zeker dat u deze inkooporder wil verwijderen?',
    field: {
      id: { label: 'ID' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          partial: 'Gedeeltelijk',
          complete: 'Gereed',
          canceled: 'Geannuleerd',
        },
      },
      supplier: { label: 'Leverancier' },
      quantity: { label: 'Aantal' },
      reference: { label: 'Referentie' },
      orderNumberErp: { label: 'Nummer' },
      orderDate: { label: 'Orderdatum' },
      receiptDate: { label: 'Ontvangstdatum', error: 'Ontvangstdatum mag niet leeg zijn.' },
      trackingCode: { label: 'Tracking Code' },
      licensePlate: { label: 'Kenteken' },
      lines: { label: 'Inkooporder regels' },
      inShipments: {
        label: 'Ontvangstorder',
        empty: 'Nog geen ontvangstorders toegevoegd.',
        value: 'Ontvangstorder {{id}}',
      },
      exactPurchaseOrder: { label: 'Inkooporder' },
      warehouse: { label: 'Magazijn' },
    },
  },
  purchaseOrderLine: {
    noBatchTypeBuy: 'Geen proces gedefinieerd',
    addToInShipmentButton: 'Toevoegen',
    field: {
      id: { label: 'ID' },
      productionRequest: { label: 'Werkorder' },
      project: { label: 'Project' },
      receiptDate: { label: 'Ontvangstdatum' },
      quantity: { label: 'Aantal' },
      code: { label: 'Artikel Code' },
      _toReceive: { label: 'Te ontvangen' },
      unitPrice: { label: 'Stuk Prijs' },
      netPrice: { label: 'Net Prijs' },
      purchaseOrder: { label: 'Inkooporder' },
      purchaseUnit: { label: 'Bestelgrootte' },
      erpLines: { label: 'ERP' },
    },
  },
  inShipment: {
    disabledDelete: 'Kan Ontvangstorder niet verwijderen omdat er actieve werkorders zijn',
    field: {
      id: { label: 'ID' },
      receivedAt: { label: 'Ontvangstdatum' },
      trackingCode: { label: 'Tracking Code' },
      licensePlate: { label: 'Kenteken' },
      shipperName: { label: 'Chauffeur' },
    },
    edit: {
      title: 'Ontvangstorder Bewerken',
    },
    missingReceiveProcessCreate: 'Missend Ontvangst Order aanmaken',
  },
  inShipmentLine: {
    disabledDelete: 'Kan regel niet verwijderen omdat werkorder actief of afgerond is',
    field: {
      id: { label: 'ID' },
      inShipment: { label: 'Ontvangst ID' },
      autoPerformedProductionRequest: { info: 'Deze regel is automatisch toegevoegd omdat er meer artikelen ontvangen zijn dan aangegeven op de Inkoop Order.'},
    },
    create: {
      title: 'Ontvangst Orderregel Toevoegen',
    },
    edit: {
      title: 'Ontvangst Orderregel Bewerken',
    },
    missingBuyProcessWarning: 'Geen Ontvangst proces gevonden voor dit artikeltype.',
    missingBuyProcessCreate: 'Missend Ontvangst proces aanmaken',
  },
  outShipment: {
    disabledDelete: 'Kan Verzamelorder niet verwijderen omdat er actieve werkorders zijn',
    field: {
      id: { label: 'ID' },
      shipperPickupDate: { label: 'Ophaaldatum' },
      shipperName: { label: 'Chauffeur' },
      shipperLicensePlate: { label: 'Kenteken' },
      freightType: { label: 'Verzendsoort' },
      freightCompany: { label: 'Transporteur' },
      freightInfo: { label: 'LadingInfo' },
      outShipmentTemplate: { label: 'Verzend Template' },
    },
    missingPickProcessCreate: 'Missend Verzamel Order proces aanmaken',
  },
  outShipmentLine: {
    disabledDelete: 'Kan regel niet verwijderen omdat werkorder actief of afgerond is',
    field: {
      id: { label: 'ID' },
      outShipment: { label: 'Verzamel ID' },
    },
    create: {
      title: 'Verzamel Order Toevoegen',
    },
    edit: {
      title: 'Verzamel Order Bewerken',
    },
    missingSellProcessWarning: 'Geen Verzend proces gevonden voor dit artikeltype.',
    missingSellProcessCreate: 'Missend Verzend proces aanmaken',
  },
  workSchedule: {
    field: {
      employer: { label: 'Werkgever', placeholder: 'Begin met typen om werkgevers te zoeken of toe te voegen' },
      startDate: { label: 'Ingangsdatum' },
      workSlots: {
        label: 'Werktijden',
        empty: 'Er zijn nog geen werktijden toegevoegd.',
      },
      allowedLegalLeaveMinutes: { label: 'Wettelijke Vakantie' },
      allowedExtraLeaveMinutes: { label: 'Bovenwettelijke Vakantie' },
      allowedRwhMinutes: { label: 'ADV' },
    },
  },
  capability: {
    overview: {
      title: 'Vaardigheden',
      empty: 'Er zijn geen vaardigheden die voldoen aan uw zoekopdracht.',
      addButton: 'Vaardigheid',
    },
    create: {
      title: 'Vaardigheid Aanmaken',
    },
    edit: {
      title: 'Vaardigheid Aanpassen',
    },
    field: {
      name: { label: 'Naam' },
      operatorCapabilities: {
        label: 'Operators',
        none: 'Er zijn nog geen operators geconfigureerd in het systeem.',
      },
    },
  },
  processPhase: {
    overview: {
      title: 'Proces Fases',
      empty: 'Er zijn geen proces fases die voldoen aan uw zoekopdracht.',
      addButton: 'Proces Fase',
    },
    create: {
      title: 'Proces Fase Aanmaken',
    },
    edit: {
      title: 'Proces Fase Aanpassen',
    },
    field: {
      icon: { label: 'Icoon' },
      name: { label: 'Naam' },
    },
  },
  batchType: {
    overview: {
      title: 'Processen',
      empty: 'Geen processen gevonden.',
    },
    create: {
      title: 'Proces aanmaken',
    },
    onTheFly: {
      title: 'Flexibele Registratie instellingen',
      description: "Door het instellen van 'Flexibele Registratie' kan TRACY automatisch een batch aanmaken op het moment dat een serienummer gescand wordt dat nog niet bekend is. Hierbij wordt wel gecontroleerd of het ingevoerde serienummer aan het format voldoet.",
    },
    edit: {
      title: 'Proces aanpassen',
      bulkTemplateButton: 'Template bulk toevoegen',
      bulkTemplateModal: {
        title: 'Template aan artikels toevoegen',
        selectButton: 'Voeg aan {{count}} artikel toe',
        selectButton_plural: 'Voeg aan {{count}} artikels toe',
      },
    },
    field: {
      id: { label: 'ID' },
      description: { label: 'Omschrijving' },
      quantity: { label: 'Batchgrootte' },
      name: { label: 'Batchgrootte' },
      articleType: { label: 'Artikel type' },
      type: {
        label: 'Type',
        value: {
          make: 'Maak',
          buy: 'Ontvangen',
          sell: 'Verzenden',
          transfer_line: 'Voorraadverplaatsingsregel',
          component: 'Component',
          subassembly: 'Subassembly',
          stock_count: 'Voorraadtelling',
          stock_count_template: 'Template Voorraadtelling',
          transfer: 'Voorraadverplaatsing',
          on_the_fly: 'Flexibele Registratie',
          pick_order: 'Order Verzamelen',
          receive_order: 'Order Ontvangst',
          make_template: 'Template Artikel Maken',
          buy_template: 'Template Artikel Ontvangen',
          sell_template: 'Template Artikel Verzenden',
          transfer_line_template: 'Template Voorraadverplaatsingsregel',
        },
      },
      serialNumberFormat: { label: 'Serienummer Format' },
      default: { label: 'Standaard' },
      source: { label: 'Template' },
      targets: {
        label: 'Artikeltypes',
        value: 'Gebruikt in {{count}} artikeltype',
        value_plural: 'Gebruikt in {{count}} artikeltypes'
      },
      onTheFlyType: {
        label: 'Flexibele Registratie Batch grootte',
        info: 'Deze instelling bepaalt welke grootte een batch krijgt als deze tussentijds wordt aangemaakt',
        value: {
          none: 'Geen',
          fixed: 'Vast',
          flexible: 'Flexibel',
        },
        tooltip: {
          none: 'Als je deze optie selecteert, negeren we de echte grootte van de aangemaakte batch en kan deze oneindig gebruikt worden.',
          fixed: 'Als je deze optie selecteert, moet je een vaste grootte voor de aangemaakte batches invullen.',
          flexible: 'Als je deze optie selecteert, moet de operator de batch grootte invullen zodra een nieuwe batch wordt aangemaakt.',
        },
      },
      onTheFlySource: {
        label: 'Flexibele Registratie Bron',
        info: 'Deze instelling bepaalt waar vanuit de nieuwe batch aangemaakt wordt.',
        value: {
          new: 'Nieuw',
          existing: 'Bestaand',
        },
        tooltip: {
          new: 'Er wordt een nieuwe batch gemaakt.',
          existing: 'Het systeem zal vragen om een bestaande batch te scannen waar de nieuwe batch uitgehaald zal worden als een subbatch.',
        },
      },
    },
  },
  articleType: {
    overview: {
      title: 'Artikeltypes',
      empty: 'Er zijn geen artikeltypes die voldoen aan uw zoekopdracht.',
      addButton: 'Artikeltype',
      viewBOMButton: 'Bekijk stuklijst',
      copyBatchTypeButton: 'Kopieer Batch Types',
    },
    printShelfLabelButton: 'Magazijnlocatie label',
    selectModal: {
      title: 'Kies een Artikeltype',
    },
    create: {
      title: 'Artikeltype Aanmaken',
    },
    edit: {
      baseFields: {
        label: 'Basis Velden',
        info: 'Deze velden behoren niet tot een procestype maar zijn toepasselijk op het gehele artikeltype.',
      },
      title: 'Artikeltype Aanpassen',
      version: 'Versie {{version}}',
      draft: 'Concept',
      finalizeButton: 'Afronden',
      nextVersionButton: 'Volgende Versie',
      noStepSelected: 'Geen stap geselecteerd',
      workStationsModal: {
        title: 'Selecteer Werkplekken',
      },
      copyPaste: {
        copyButton: 'Kopieer',
        pasteButton: 'Plak',
        copied: 'Succesvol gekopieerd',
        pasted: 'Succesvol geplakt',
        pasteError: 'Er kan niks geplakt worden',
      },
      bomFieldsModal: {
        title: 'Stuklijst Velden',
        content: 'Dit process bevat velden van het type \'Stuklijst\'. Wisselen naar een ander batch type dan \'maak\' zal er voor zorgen dat deze velden worden verwijderd.',
      },
      endWithVariableQuantity: 'Dit proces kan niet eindige met een batch die variabel is. Voeg een veld "grootte" toe om de daadwerkelijke grootte van de batch te bepalen.',
      splitWithVariableQuantity: 'Een batch stap kan niet gebruikt worden op een variabele batch.',
      quantityFieldWithoutVariableQuantity: 'Een grootte veld kan niet worden gebruikt zonder een variabele batch.',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      name: { label: 'Naam' },
      line: { label: 'Productieomgeving' },
      versions: { label: 'Versies' },
      batchTypes: { label: 'Processen' },
      articleType: { label: 'Artikel type' },
      componentType: { label: 'Component type' },
      classification: { label: 'Groep' },
      startDate: { label: 'Start Datum' },
      endDate: { label: 'End Datum' },
      isMake: { label: 'Maak' },
      isBuy: { label: 'Koop' },
      isSerial: { label: 'Serieel' },
      isMakeOrBuy: { label: 'Maak/Koop' },
      storageLocations: { short: 'Opslaglocatie(s)', long: 'Opslaglocatie(s) - Hoeveelheid' },
      totalStock: { label: 'Totale Voorraad' },
      storageLocationStock: { label: 'Opslaglocatie Voorraad' },
    },
  },
  articleTypeVersion: {
    field: {
      articleType: { label: 'Artikeltype' },
      productionLineVersion: { label: 'Productieomgeving Versie' },
    },
  },
  productionLine: {
    overview: {
      title: 'Productieomgevingen',
      empty: 'Er zijn geen productieomgevingen die voldoen aan uw zoekopdracht.',
      addButton: 'Productieomgeving',
    },
    planning: {
      title: 'Productie omgevingen',
      empty: 'Geen productieomgevingen gevonden',
      productionLine: 'Productieomgeving',
      week: 'Week',
      days: {
        monday: 'Maandag',
        tuesday: 'Dinsdag',
        wednesday: 'Woensdag',
        thursday: 'Donderdag',
        friday: 'Vrijdag',
        saturday: 'Zaterdag',
        sunday: 'Zondag',
      },
      parts: {
        night: 'Nacht (00:00 - 06:00)',
        morning: 'Ochtend (06:00 - 12:00)',
        afternoon: 'Middag (12:00 - 18:00)',
        evening: 'Avond (18:00 - 00:00)',
      },
    },
    create: {
      title: 'Productieomgeving Aanmaken',
    },
    edit: {
      baseFields: {
        label: 'Globale instellingen',
        info: 'Deze velden behoren niet tot een versie maar zijn toepasselijk op elke versie.',
      },
      title: 'Productieomgeving Aanpassen',
      version: 'Versie {{version}}',
      draft: 'Concept',
      finalizeButton: 'Afronden',
      nextVersionButton: 'Volgende Versie',
      tabs: {
        map: 'Kaart',
        codes: 'Codes',
        availability: 'Beschikbaarheid',
      },
    },
    selectModal: {
      title: 'Kies een Productie',
    },
    codesModal: {
      title: 'Codes Overzicht',
      noPrintersAvailable: 'Er zijn geen printers beschikbaar.',
    },
    field: {
      name: { label: 'Naam' },
      fullName: { label: 'Naam' },
      versions: { label: 'Versies' },
      timezone: { label: 'Tijdzone' },
      color: {
        label: 'Kleur',
        value: {
          red: 'Rood',
          orange: 'Oranje',
          yellow: 'Geel',
          olive: 'Olijfgroen',
          green: 'Groen',
          teal: 'Turquoise',
          blue: 'Blauw',
          violet: 'Violet',
          purple: 'Paars',
          pink: 'Roze',
          brown: 'Bruin',
          grey: 'Grijs',
          black: 'Zwart',
        },
      },
      workSchedules: {
        label: 'Roosters',
        errors: {
          missing: 'Productielijn moet een geldig rooster hebben.',
        }
      },
      leaveCalendars: { label: 'Verlofkalendars' },
      factory: { label: 'Fabriek' },
    },
  },
  productionLineVersion: {
    field: {
      version: { label: 'Versie' },
      draft: { label: 'Concept' },
      floorPlan: { label: 'Plattegrond' },
      workStations: {
        label: 'Werkplekken',
        addButton: 'Werkplek',
        error: {
          missing: 'Ten minste een werkplek is vereist.',
        }
      },
      productionLine: { label: 'Productieomgeving' },
    },
  },
  workStation: {
    field: {
      name: { label: 'Naam' },
      code: { label: 'Code' },
      dataSources: { label: 'Databronnen' },
      defaultFte: { label: 'Standaard FTE' },
      singleUser: {
        label: 'Aantal Logins',
        value: {
          true: 'Single Login',
          false: 'Multi Login',
        },
        tooltip: {
          true:
            "Wanneer ingesteld als 'single login' zal het werkstation eenmalig de badge scan vragen aan de operator. Na 4u zal er automatisch worden uitgelogd.",
          false:
            "Wanneer ingesteld als 'multi login' zal het werkstation bij elke nieuwe stap vragen om de badge scan.",
        },
      },
      productionStrategy: {
        label: 'Productiestrategie',
        value: {
          together: 'Samen',
          seperate_spread: 'Gescheiden (Verdeeld)',
          seperate_planned: 'Gescheiden (Gepland)',
        },
        tooltip: {
          together: "Wanneer ingesteld als 'samen' zullen de verschillende locaties samen werken als één locatie.",
          seperate_spread:
            "Wanneer ingesteld als 'gescheiden (verdeeld)' zullen de verschillende locaties gescheiden werken aan dezelfde productie opdrachten.",
          seperate_planned:
            "Wanneer ingesteld als 'gescheiden (gepland)' zullen de verschillende locaties gescheiden werken aan productie opdrachten die per locatie worden ingepland.",
        },
      },
      maintenanceStrategy: {
        label: 'Onderhoudsstrategie',
        value: {
          together: 'Samen',
          seperate: 'Gescheiden',
        },
        tooltip: {
          together:
            "Wanneer ingesteld als 'samen' zal onderhoud aan de verschillende locaties samen uitgevoerd en gepland worden voor alle locaties.",
          seperate:
            "Wanneer ingesteld als 'gescheiden' zal onderhoud aan de verschillende locaties gescheiden uitgevoerd en gepland worden per locatie.",
        },
      },
      steps: { label: 'Stappen' },
      workAssigned: { label: 'Gepland' },
      workUnassigned: { label: 'Ongepland' },
      work: { label: 'Werk' },
      productionRequests: { label: 'Werkorders' },
      automationDate: { label: 'Automatiseringsdatum' },
    },
    planning: {
      title: 'Dagplanning',
      empty: 'Er is niks om in te plannen voor deze dag.',
      assignmentsModal: {
        title: 'Dagplanning: {{name}}',
      },
      assignmentTimeModal: {
        title: 'Tijden Aanpassen',
        overlap: {
          label: 'Deze tijden overlappen met al geplande tijdsvakken voor deze operator.',
          assignment: '{{workStation}} van {{start}} tot {{end}}.',
        },
      },
      day: {
        today: 'Vandaag',
        tomorrow: 'Morgen',
        other: 'Anders',
      },
      stats: {
        work: 'Uren werk',
        workAssigned: 'Geplande uren',
        workUnassigned: 'Ongeplande uren',
        month: {
          january: 'JANUARI',
          february: 'FEBRUARI',
          march: 'MAART',
          april: 'APRIL',
          may: 'MEI',
          june: 'JUNI',
          july: 'JULI',
          august: 'AUGUSTUS',
          september: 'SEPTEMBER',
          october: 'OKTOBER',
          november: 'NOVEMBER',
          december: 'DECEMBER',
        },
      },
    },
    start: {
      title: 'Werkplek Starten',
      startButton: 'Starten',
    },
    production: {
      title: 'Productie',
      empty: 'Er zijn geen productieopdrachten die voldoen aan uw zoekopdracht.',
      stopButton: 'Stoppen',
      selectButton: 'Produceren',
      version: 'Versie {{version}}',
      operatorModal: {
        title: 'Scan Badge ID',
        confirmButton: 'Bevestigen',
        error: {
          noResults: 'Geen operators gevonden met het gegeven Badge ID.',
          emptyField: 'Geef de Badge ID van de operator in.',
          multipleResults: 'Meerdere operators gevonden met het gegeven Badge ID.',
          unexpected: 'Er is een onverwachte fout opgetreden.',
          missingCapabilities: 'De gevonden operator mist een benodigde vaardigheid: {{capabilities}}.',
          missingCapabilities_plural: 'De gevonden operator mist enkele benodigde vaardigheden: {{capabilities}}.',
        },
      },
      batchModal: {
        title: 'Scan Product',
        confirmButton: 'Bevestigen',
        error: {
          notFound: 'Geen product gevonden met het gegeven serienummer.',
        },
      },
      performModal: {
        generalErrors: 'Er zijn wat fouten voorgekomen',
        title: 'Voer Stap Uit',
        confirmButton: 'Bevestigen',
        formStep: {
          label: 'Serienummer',
          subject: {
            article_type: 'Artikel',
          },
        },
        printStep: {
          selectPrinter: 'Printer Selecteren',
          noPrintersAvailable: 'Geen Printers Beschikbaar',
          printButton: 'Printen',
          printedSuccesfully: 'Succesvol geprint',
        },
        splitStep: {
          printButton: 'Printen',
          printAllButton: 'Alles Printen ({{count}})',
          reprintButton: 'Opnieuw Printen',
          reprintButton_plural: 'Opnieuw Printen ({{count}})',
          bulkEntrySerialNumbersDetail: 'U kunt meerdere serienummers tegelijk scannen.',
          wrongFormat: 'Het serienummer {{serialNumber}} komt niet overeen met het vooraf bepaalde formaat',
          expectedQuantity: 'U heeft het verwachte aantal serienummers al gescand.',
          integrationNotActive: 'Exact integratie is niet geactiveerd of bestaat niet.',
          alreadySyncedToExact: 'Het serienummer {{serialNumber}} is al gescand.',
        },
        quantityType: {
          quantity: 'Grootte',
          weight: 'Gewicht',
          error: {
            tooMuch: 'De ingevulde hoeveelheid is groter dan de hoeveelheid die voor deze order nog over is.',
          }
        },
        scan: {
          serialNumber: 'Serienummer',
          quantity: 'Aantal',
          error: {
            notFound: 'Er is geen batch gevonden met het opgegeven serienummer.',
            wrongArticleType: 'De batch, gevonden met het opgegeven serienummber, heeft niet het verwachtte artikeltype.',
            fullyUsed: 'De batch, gevonden met het opgegeven serienummber, heeft niet genoeg beschikbare voorraad.',
          },
        },
      },
      quantityModal: {
        title: 'Vul Batchgrootte In',
        quantity: 'Grootte',
        confirmButton: 'Bevestigen',
      },
      infoModal: {
        title: 'Info',
      },
      operator: {
        expiresAt: 'Verloopt om {{at}}',
      },
      onTheFlyQuantityModal: {
        title: 'Flexibele Registratie aantallen',
        description: 'Een aantal van de gescande batches bestaan nog niet in het systeem. Om deze middels Flexibele Registratie nu tussentijds aan te maken moet het systeem weten hoe groot deze batches zijn.',
        placeholder: 'Aantal',
      },
      onTheFlySourceModal: {
        title: 'Flexibele Registratie Bron',
        description: 'De gescande batch bestaat nog niet in het systeem. Om deze batch aan te maken moet het systeem weten waar deze batch uitgehaald is.',
        placeholder: 'Serienummer',
        error: {
          notFound: 'Er is geen batch gevonden met het opgegeven serienummer.',
          wrongArticleType: 'De batch, gevonden met het opgegeven serienummber, heeft niet het verwachtte artikeltype.',
          notEnough: 'De batch, gevonden met het opgegeven serienummber, heeft niet genoeg beschikbare voorraad.',
        },
      },
      fastForwardButton: 'Vooruitspoelen',
      fastForwardModal: {
        title: 'Vooruitspoelen',
        days: 'Dagen',
        confirmButton: 'Bevestigen',
      },
    },
    editModal: {
      editTitle: 'Werkplek configureren',
      viewTitle: 'Werkplek configuratie',
    },
    stopModal: {
      title: 'Werkplek Stoppen',
      content: 'Weet u zeker dat u wilt stoppen op deze werkplek?',
      stopButton: 'Stoppen',
    },
    day: {
      today: 'Vandaag',
      tomorrow: 'Morgen',
    },
  },
  productionRequest: {
    registerExistingButton: 'Voorraad Telling',
    overview: {
      title: 'Werk Orders',
      empty: 'Er zijn geen productie orders die voldoen aan uw zoekopdracht.',
      addButton: 'Productie Order',
      productionRequestID: 'Werkorder ID',
      inShipmentId: 'Ontvangst ID',
      outShipmentId: 'Verzamel ID',
      exactSalesOrder: 'Open Verkooporder {{order}} in Exact',
      exactPurchaseOrder: 'Open Inkooporder {{order}} in Exact',
      exactShopOrder: 'Open Productie Order {{order}} in Exact',
      purchaseOrderNumber: 'Inkooporder ID',
      stockCountNumber: 'Voorraad telling ID',
      salesOrderNumber: 'Verkooporder ID',
      warehouseTransferID: 'Magazijnoverdracht ID',
      warehouseTransferLineID: 'Magazijnoverdrachtregel ID',
      productionOrderNumber: 'Productie Order ID',
      orders: "Orders",
      done: '{{count}} product klaar',
      done_plural: '{{count}} producten klaar',
      progress: '{{percentage}} v.h. werk klaar',
      extra: '+ {{count}} extra',
      extra_plural: '+ {{count}} extra',
      diff: {
        planned: 'Gepland',
        actual: 'Actueel',
        advance: 'Voortgang',
        delay: 'Vertraging',
      },
    },
    edit: {
      title: 'Werk Order Aanpassen',
      bom: 'Stuklijst',
      storageLocations: 'OPSLAGLOCATIES',
      releaseButton: 'Vrijgeven',
      stage: {
        articleType: {
          label: 'Artikeltype Info',
          bom: {
            notEnoughStock: "Niet genoeg voorraad beschikbaar om dit aantal te produceren",
          },
          showBomBtn: "Laat stuklijst zien",
        },
        warehouse: {
          label: 'Magazijn Info',
        },
        planning: {
          label: 'Planning',
          planned: 'Gepland',
          actual: 'Gerealiseerd',
          duration: 'Looptijd',
          start: 'Start',
          end: 'Eind',
        },
        bom: {
          label: 'BOM Lijst',
          articleCode: 'Artikeltype Code',
          articleName: 'Artikeltype Naam',
        },
        productionLines: {
          label: 'Productieomgevingen',
        },
      },
    },
    field: {
      id: { label: 'Werkorder ID' },
      articleType: { label: 'Artikeltype' },
      processVersion: { label: 'Versie' },
      week: { label: 'Week' },
      quantity: { label: 'Hoeveelheid' },
      progress: { label: 'Voortgang' },
      startAt: { label: 'Verwacht Begin' },
      endAt: { label: 'Verwacht Eind' },
      duration: { label: 'Verwachte Looptijd' },
      project: { label: 'Project' },
      finished: { label: 'Afgerond' },
      productionOrder: { label: 'Productie Order' },
    },
    filter: {
      itemGroup: 'Artikel Groep',
      requestStatus: 'Werkorder Status',
      inShipmentId: 'Ontvangst ID',
      outShipmentId: 'Verzamel ID',
      warehouseTransferID: 'Overdracht ID',
      projectCode: 'Project Code',
      purchaseOrderNumberERP: 'Inkooporder ERP',
      salesOrderNumberERP: 'Verkooporder ERP',
    },
    addModal: {
      title: 'Productieopdrachten Toevoegen',
      step: {
        pickWeek: 'Kies de week',
        pickArticleTypes: 'Kies de artikeltypes',
        setQuantities: 'Kies de hoeveelheden',
      },
      quantityWarning:
        'Een productieopdracht opslaan met hoeveelheid 0 staat gelijk aan deze productieopdracht verwijderen.',
      noArticleTypes: 'U hebt nog geen artikeltypes geselecteerd.',
      notFullyDeleted: 'Productieopdracht kon niet volledig verwijderd worden omdat de opdracht al gestart is.',
    },
    editModal: {
      title: 'Productieopdracht Aanpassen',
      title_plural: 'Productieopdrachten Aanpassen',
    },
  },
  globalValue: {
    edit: {
      unsetButton: 'Leegmaken',
      title: '{{key}} Aanpassen',
      operator_badge_print: {
        key: {
          operator_name: 'Naam van de operator.',
          badge_id: 'Badge ID van de operator.',
        },
      },
      workstation_code_print: {
        key: {
          workstation_name: 'Naam van het werkstation.',
          workstation_code: 'Code van het werkstation.',
        },
      },
      stock_location_print: {
        key: {
          article_type_code: 'Code van het artikeltype.',
          article_type_name: 'Naam van het artikeltype.',
          warehouse_code: 'Naam van het magazijn.',
          warehouse_locations: 'Magazijnlocaties.',
        },
      },
      loading_carrier_print: {
        key: {
          loading_carrier_serial: 'Serienummer van de ladingdrager',
          loading_carrier_name: 'Naam van de ladingdrager',
        },
      },
      login_url_print: {
        key: {
          slug: 'De slug van je tracy instantie. ({{value}})',
          app_url: 'De basis url van tracy. ({{value}})',
        },
      },
    },
    overview: {
      title: 'Algemene instellingen',
      template: {
        empty: 'Template is leeg',
        moreLines: '{{count}} regel niet getoond, hover om te tonen.',
        moreLines_plural: '{{count}} regels niet getoond, hover om te tonen.',
      },
    },
    field: {
      key: {
        label: 'Naam instelling',
      },
      value: {
        label: 'Waarde',
      },
      writable: {
        label: 'Schrijfbaar?',
      },
      readable: {
        label: 'Leesbaar?',
      },
    },
  },
  template: {
    field: {
      name: { label: 'Naam' },
      file: { label: 'PDF' },
      type: {
        label: 'Type',
        value: {
          out_shipment: 'Pakbon',
        },
      },
      isDefault: { label: 'Standaard' },
    },
    overview: {
      title: 'Templates',
      empty: 'Er zijn nog geen templates toegevoegd',
      addButton: 'Template',
    },
    create: {
      title: 'Template aanmaken',
    },
    edit: {
      title: 'Template aanpassen',
    },
    info:
      'In een template kun je waardes die betrekking hebben tot het product terug laten komen. Je kunt aangeven waar een waarde moet komen door {{ te typen op de juiste plek in de template.',
  },
  globalFile: {
    edit: {
      title: 'Bijzonder bestand bewerken',
    },
    overview: {
      title: 'Bijzondere bestanden',
    },
    field: {
      key: {
        label: 'Naam voor bestand',
      },
      value: {
        label: 'Bestand',
      },
      writable: {
        label: 'Schrijfbaar?',
      },
      readable: {
        label: 'Leesbaar?',
      },
    },
  },
  myFilter: {
    custom: 'Nieuw',
    action: {
      unsetDefault: 'Gebruik niet als standaard',
      setDefault: 'Gebruik als standaard',
      setCurrent: 'Sla huidige filters op',
      delete: 'Verwijderen',
    },
  },
  tooltips: {
    masquerade: 'Voordoen als',
    edit: 'Aanpassen',
    delete: 'Verwijderen',
    restore: 'Terugzetten',
    view: 'Bekijken',
    select: 'Selecteren',
  },
  form: {
    // Model
    edit: {
      addFieldButton: 'Veld Toevoegen',
    },
    field: {
      fields: {
        label: 'Velden',
        empty: 'Dit formulier is nog leeg',
      },
    },
    // Form stuff
    yes: 'Ja',
    no: 'Nee',
    either: 'Beide',
    draftJs: {
      style: {
        'header-one': { label: 'H1' },
        'header-two': { label: 'H2' },
        'header-three': { label: 'H3' },
        'header-four': { label: 'H4' },
        'header-five': { label: 'H5' },
        'header-six': { label: 'H6' },
        blockquote: { label: 'Blockquote' },
        'unordered-list-item': { label: 'UL' },
        'ordered-list-item': { label: 'OL' },
        'code-block': { label: 'Code Block' },
        BOLD: { label: 'Bold' },
        ITALIC: { label: 'Italic' },
        UNDERLINE: { label: 'Underline' },
        CODE: { label: 'Monospace' },
      },
    },
    iconInput: {
      noneSelected: 'Geen icoon geselecteerd',
    },
    cancelButton: 'Annuleren',
    deleteButton: 'Verwijderen',
    restoreButton: 'Herstellen',
    backButton: 'Terug',
    nextButton: 'Volgende',
    submitButton: 'Opslaan',
    saveButton: 'Opslaan',
    copyButton: 'Kopiëren',
    addButton: 'Toevoegen',
    printButton: 'Printen',
    downloadButton: 'Downloaden',
    applyButton: 'Toepassen',
    bulkActionButton: 'Actie ({{count}})',
    fileType: {
      pdf: 'PDF',
      image: 'IMG',
      xls: 'XLS',
      any: 'Anders',
      none: 'Nog geen bestand geüpload',
    },
    multiPick: {
      searchPlaceholder: 'Selecteer…',
      selectedText: '$1 uit $2 geselecteerd',
      noneSelectedText: 'Geen geselecteerd',
      selectAllButton: 'Alle',
      selectNoneButton: 'Geen',
    },
    notifications: {
      saveSuccess: 'Succesvol opgeslagen',
      deleteSuccess: 'Succesvol verwijderd',
      saveError: 'Fout met opslaan ({{status}})',
      saveValError: 'Niet alle informatie is correct ingevuld',
      saveAuthError: 'Niet geauthorizeerd',
      newAppVersion: 'Nieuwe versie beschikbaar, klik om de pagina te verversen',
    },
    startDate: 'Startdatum',
    endDate: 'Einddatum',
    deleteConfirmation: 'Weet u zeker dat u {{name}} wilt verwijderen?',
    repeat: 'Herhaal',
    greaterThan: 'Hoger dan',
    lowerThan: 'Lager dan',
    greaterThanOrEqual: '>=',
    lowerThanOrEqual: '<=',
    duration: {
      hours: 'Uren',
      minutes: 'Minuten',
      seconds: 'Seconden',
    },
    cap: {
      remaining: '{{count}} over',
      tooMany: '{{count}} te veel',
    },
  },
  crash: {
    heading: 'Applicatie gecrasht',
    subHeading1: 'Onze excuses, maar de applicatie is gecrasht.',
    subHeading2: 'Ons team is op de hoogte gesteld.',
    reload: 'Herlaad pagina',
  },
  daycy: {
    week: {
      label: 'Wk',
      number: '{{week}}',
      value: 'W{{week}} - {{year}}',
    },
    weekDay: {
      monday: 'Ma',
      tuesday: 'Di',
      wednesday: 'Wo',
      thursday: 'Do',
      friday: 'Vr',
      saturday: 'Za',
      sunday: 'Zo',
    },
    month: {
      january: 'Januari',
      february: 'Februari',
      march: 'Maart',
      april: 'April',
      may: 'Mei',
      june: 'Juni',
      july: 'Juli',
      august: 'Augustus',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'December',
    },
    period: {
      am: 'AM',
      pm: 'PM',
    },
  },
  iconModal: {
    header: 'Icoon Uitkiezen',
    filterPlaceholder: 'Zoeken...',
    category: {
      accessibility: 'Toegangelijkheid',
      arrows: 'Pijlen',
      audioVideo: 'Audio/Video',
      business: 'Business',
      chess: 'Schaken',
      code: 'Code',
      communication: 'Communicatie',
      computers: 'Computers',
      currency: 'Valuta',
      dateTime: 'Datum/Tijd',
      design: 'Design',
      editors: 'Tekstverwerking',
      files: 'Bestanden',
      genders: 'Geslachten',
      handsGestures: 'Handgebaren',
      health: 'Gezondheid',
      images: 'Afbeeldingen',
      interfaces: 'Interfaces',
      logistics: 'Logistiek',
      maps: 'Kaarten',
      medical: 'Medisch',
      objects: 'Objecten',
      paymentsShopping: 'Betalingen/Shoppen',
      shapes: 'Vormen',
      sports: 'Sport',
      status: 'Status',
      usersPeople: 'Mensen',
      vehicles: 'Voertuigen',
      writing: 'Schrijven',
    },
  },
  exact: {
    ok: {
      title: 'Exact Koppeling Succesvol',
      body: 'Exact Online is nu succesvol gekoppeld met het systeem.',
    },
    error: {
      title: 'Exact Koppeling Onsuccesvol',
      body: {
        invalid_request: 'Onjuiste request verstuurd naar Exact Online.',
        invalid_client: 'Onjuiste client verstuurd naar Exact Online.',
        invalid_grant: 'Onjuiste grant verstuurd naar Exact Online.',
        invalid_scope: 'Onjuiste scope verstuurd naar Exact Online. ',
        unauthorized_client: 'Ongeauthorizeerde client verstuurd naar Exact Online.',
        unsupported_grant_type: 'Niet ondersteund grant type verstuurd naar Exact Online.',
      },
    },
  },
  unit4: {
    ok: {
      title: 'Unit 4 Koppeling Succesvol',
      body: 'Unit 4 is nu succesvol gekoppeld met het systeem.',
    },
    error: {
      title: 'Unit 4 Koppeling Onsuccesvol',
      body: {
        invalid_request: 'Onjuiste request verstuurd naar Unit 4.',
        invalid_client: 'Onjuiste client verstuurd naar Unit 4.',
        invalid_grant: 'Onjuiste grant verstuurd naar Unit 4.',
        invalid_scope: 'Onjuiste scope verstuurd naar Unit 4. ',
        unauthorized_client: 'Ongeauthorizeerde client verstuurd naar Unit 4.',
        unsupported_grant_type: 'Niet ondersteund grant type verstuurd naar Unit 4.',
      },
    },
  },
  warehouse: {
    stock: 'Voorraad',
    inbound: 'Inbound',
    outbound: 'Outbound',
    field: {
      code: { label: 'Code' },
      name: { label: 'Naam' },
    },
  },
  step: {
    edit: {
      step: 'Stap {{index}}',
      start: 'Begin',
      end: 'Eind',
      addSectionButton: 'Onderdeel Toevoegen',
    },
    field: {
      newSerialNumberFormat: { label: 'Format nieuw serienummer' },
      label: { label: 'Label' },
      type: {
        value: {
          print: 'Printen per ordergrootte',
          form: 'Formulier uitvoeren per ordergrootte',
          split: 'Combi process per batchgrootte',
          multiplier: 'Product multiplicator toevoegen',
          grow: 'Groei stap',
          subprocesses: 'Subprocessen',
        },
        valueStep: {
          print: 'Print stap',
          form: 'Formulier stap',
          split: 'Splits stap',
          multiplier: 'Meerdere keren stap',
          grow: 'Groei Stap',
          subprocesses: 'Subprocessen',
        },
      },
      sections: {
        label: 'Beschrijving',
        empty: 'Deze beschrijving is nog leeg.',
      },
      workStation: { label: 'Werkplek' },
      workMinutes: { label: 'Geschatte werktijd in minuten' },
      capabilities: { label: 'Benodigde Vaardigheden' },
      workTempoManual: {
        label: 'Werktempo (Handmatig)',
        unit: 'in planten / uur',
      },
      workTempoAutomatic: {
        label: 'Werktempo (Automatisch)',
        unit: 'in planten / uur',
      },
      operatorSupervision: {
        label: 'Operator Supervisie Vereist',
      },
    },
  },
  printStep: {
    field: {
      type: {
        label: 'Printtype',
        value: {
          label: 'Label',
          document: 'Document',
        },
      },
      copies: { label: 'Aantal Kopieën' },
      labelPrinter: {
        label: 'Printer',
        value: {
          altec: 'Altec',
          zebra: 'Zebra/Toshiba',
        },
      },
      labelTemplate: {
        label: 'Template',
        keys: {
          article_type_name: 'De naam van het artikel.',
          article_type_code: 'De code van het artikel.',
          warehouse_name: 'De naam van het warehouse.',
          warehouse_code: 'De code van het warehouse.',
          warehouse_locations: 'De locaties van het magazijn.',
          serial_number: 'Het serienummer van het product.',
          date: 'De datum op het moment van printen.',
          time: 'De tijd op het moment van printen.',
          shipment_lines: 'Overzicht van pakbon.',
        },
      },
      documentBackground: { label: 'Achtergrond' },
      documentOverlays: {
        label: 'Template',
        template: 'Template',
        addPage: 'Pagina',
      },
    },
  },
  multiplierStep: {
    field: {
      multiplier: { label: 'Aantal keer' },
    },
  },
  formStep: {
    field: {
      form: { label: 'Formulier' },
      icon: { label: 'Icoon' },
    },
    perform: {
      between: 'Tussen {{min}} en {{max}}',
    },
  },
  splitStep: {
    field: {
      form: { label: 'Formulier' },
      icon: { label: 'Icoon' },
      newBatchQuantity: {
        label: 'Nieuwe Batchgrootte',
        info: 'Een container bevat een batch met X aantal planten. X is in dit geval de batchgrootte.',
      },
      newBatchVariableQuantity: {
        label: 'Variabel',
        info: 'Grootte is een verwachte grootte, de daadwerkelijke grootte kan variëren.',
      },
      newBatchVariableQuantityPredetermined: {
        label: 'Variabel Voorbepaald',
        info: 'De variable grootte is voorbepaald, dit maakt het mogelijk voor de grootte om de stap zelf te beinvloeden, bijvoorbeeld voor sommige formulier velden of hoeveel je moet scannen.',
      },
      printer: { label: 'Printer' },
      template: { label: 'Template' },
      copies: { label: 'Aantal Kopieën' },
      type: {
        label: 'Identificatietype',
        value: {
          scan: 'Scan',
          print: 'Print',
          article: 'Artikel',
          provided: 'Opgegeven serienummer',
        },
        tooltip: {
          scan:
            "Bij het identificatietype 'Scan' wordt de batch in een ladingdrager geladen die vervolgens gescand wordt door de operator.",
          print: "Bij het identificatietype 'Print' wordt er een label geprint met het serienummer van de batch.",
          article:
            "Bij het identificatietype 'Artikel' moet er tenminste één artikel gekoppeld worden waarvan het serienummer gebruikt kan worden.",
          provided: "Bij identificatie door middel van 'Opgegeven serienummer' wordt door de operator het serienummer verstrekt waarmee de partij dient te worden geïdentificeerd. Dit moet nog steeds overeenkomen met het nieuwe serienummerformaat dat hierboven is gedefinieerd.",
        },
      },
    },
  },
  formStepField: {
    field: {
      label: { label: 'Label' },
      type: {
        label: 'Type',
        value: {
          text: 'Text',
          check: 'Check',
          choice: 'Keuze',
          article_type: 'Artikeltype',
          measure: 'Meting',
          format: 'Format',
          variable: 'Variabele',
          image: 'Afbeelding',
          bom: 'Stuklijst',
          quantity: 'Grootte',
          storage_location: 'Opslaglocatie'
        },
      },
      target: { label: 'Details' },
      articleType: { label: 'Artikeltype' },
      slug: {
        label: 'Print Referentie',
        info:
          'Deze waarde wordt gebruikt om te refereren naar dit veld vanuit print templates. Kan enkel de tekens A-Z, a-z, 0-9 en _ bevatten en mag niet beginnen met een cijfer.',
      },
    },
  },
  batch: {
    batchInfoModal: {
      trigger: 'Batch Info',
    },
    overview: {
      title: 'Producten',
      empty: 'Er zijn geen producten die voldoen aan uw zoekopdracht.',
    },
    field: {
      batchType: { label: 'Type' },
      productionRequest: { label: 'Werkorder ID' },
      inShipment: { label: 'Ontvangst ID' },
      outShipment: { label: 'Verzamel ID' },
      warehouseTransfer: { label: 'Overdracht ID' },
      purchaseOrderNumberERP: { label: 'Inkooporder ERP' },
      salesOrderNumberERP: { label: 'Verkooporder ERP' },
      productionOrderNumberERP: { label: 'Productie Order ERP' },
      orderNumberERP: { label: 'ERP Order' },
      batchUseds: {
        label: 'Hoofdproduct',
        empty: 'Dit product heeft geen verdere Hoofdproducten.',
      },
      batchUsings: {
        label: 'Subassemblages',
        empty: 'Dit product heeft geen subassemblages.',
      },
      details: {
        label: 'Details',
        empty: 'Dit product heeft geen details.',
      },
      performances: {
        label: 'Uitgevoerde Stappen',
        empty: 'Dit product heeft geen uitgevoerde stappen.',
        description: '{{step}} uitgevoerd door {{operator}} op {{createdAt}}',
      },
      serialNumber: { label: 'Serienummer' },
      finished: { label: 'Klaar' },
      scrapped: { label: 'Afgekeurd' },
      loadCarrier: { label: 'Ladingdrager' },
    },
  },
  subassembly: {
    overview: {
      title: 'Subassemblages',
      empty: 'Er zijn geen subassemblages die voldoen aan uw zoekopdracht.',
    },
    field: {
      parentBatch: { label: 'Hoofdproduct' },
      parentSubassembly: { label: 'Hoofdsubassemblage' },
      subassemblies: {
        label: 'Subassemblages',
        empty: 'Deze subassemblage heeft geen subassemblages.',
      },
      details: {
        label: 'Details',
        empty: 'Deze subassemblage heeft geen details.',
      },
      performances: {
        label: 'Uitgevoerde Stappen',
        empty: 'Deze subassemblage heeft geen uitgevoerde stappen.',
        description: '{{step}} uitgevoerd door {{operator}} op {{createdAt}}',
      },
      serialNumber: { label: 'Serienummer' },
      finished: { label: 'Klaar' },
    },
  },
  progress: {
    empty: 'Er zijn nog geen productieopdrachten voor deze week.',
    productionLine: {
      stat: {
        todo: 'Te doen',
        inProgress: 'Bezig',
        done: 'Klaar',
        operators: 'Operator',
        operators_plural: 'Operators',
      },
    },
    weekPicker: 'Week Selectie',
    processVersion: 'v{{version}}',
    groupBy: {
      label: 'Groeperen Op',
      value: {
        workStation: 'Werkplek',
        step: 'Stap',
      },
    },
  },
  detail: {
    field: {
      value: { label: 'Waarde' },
      reason: { label: 'Reden' },
    },
  },
  targetInfoModal: {
    scrapped: 'Afgekeurd: {{reason}}',
    variableQuantity: 'Variabele Grootte',
    reprint: {
      title: 'Herprint',
      quantity: 'Aantal kopieën',
      printButton: 'Print',
    },
  },
  serialNumberFormat: {
    part: {
      text: {
        label: 'Tekst',
        content: 'Inhoud',
      },
      date: {
        label: 'Datum',
        part: {
          label: 'Onderdeel',
          year: { label: 'Jaar' },
          month: {
            label: 'Maand',
            text: 'Tekst',
            names: {
              january: {
                label: 'Januari',
                value: 'JAN',
              },
              february: {
                label: 'Februari',
                value: 'FEB',
              },
              march: {
                label: 'Maart',
                value: 'MAA',
              },
              april: {
                label: 'April',
                value: 'APR',
              },
              may: {
                label: 'Mei',
                value: 'MEI',
              },
              june: {
                label: 'Juni',
                value: 'JUN',
              },
              july: {
                label: 'Juli',
                value: 'JUL',
              },
              august: {
                label: 'August',
                value: 'AUG',
              },
              september: {
                label: 'September',
                value: 'SEP',
              },
              october: {
                label: 'Oktober',
                value: 'OKT',
              },
              november: {
                label: 'November',
                value: 'NOV',
              },
              december: {
                label: 'December',
                value: 'DEC',
              },
            },
          },
          day: { label: 'Dag' },
          isoyear: { label: 'ISO Jaar' },
          isoweek: { label: 'ISO Week' },
          isoweekday: {
            label: 'ISO Weekdag',
            text: 'Tekst',
            names: {
              monday: {
                label: 'Maandag',
                value: 'MA',
              },
              tuesday: {
                label: 'Dinsdag',
                value: 'DI',
              },
              wednesday: {
                label: 'Woensdag',
                value: 'WO',
              },
              thursday: {
                label: 'Donderdag',
                value: 'DO',
              },
              friday: {
                label: 'Vrijdag',
                value: 'VR',
              },
              saturday: {
                label: 'Zaterdag',
                value: 'ZA',
              },
              sunday: {
                label: 'Zondag',
                value: 'ZO',
              },
            },
          },
        },
        format: 'Format',
      },
      code: {
        label: 'Code',
        alphabet: 'Alfabet',
        digits: 'Aantal',
        expand: 'Overloop',
        defaultAlphabets: {
          decimal: 'Dec',
          binary: 'Bin',
          hexadecimalLower: 'Hex (a)',
          hexadecimalUpper: 'Hex (A)',
          octal: 'Oct',
          alphanumericLower: 'Alfanum (a)',
          alphanumericUpper: 'Alfanum (A)',
          alphabeticLower: 'Alfa (a)',
          alphabeticUpper: 'Alfa (A)',
          custom: 'Anders, namelijk:',
        },
      },
      anything: {
        label: 'Iets',
      },
    },
  },
  performanceResetModal: {
    title: 'Product Terugzetten',
    reason: 'Reden',
    resetButton: 'Terugzetten',
  },
  textPart: {
    field: {
      text: {
        placeholder: 'Geen tekst ingevuld',
      },
    },
  },
  imagePart: {
    field: {
      subtitle: {
        placeholder: 'Geen ondertitel ingevuld',
      },
    },
  },
  printerSetup: {
    connectionError: 'Error tijdens het verbinden met de printer, ',
    connectionNameError: 'Error tijdens het verbinden met de printer, deze printer naam is niet bekend',
    connectionPdfError: 'Error tijdens het ophalen van het verstuur label',
    title: 'Printer Setup',
    installQzTray: {
      beforeLink: 'Installeer ',
      link: 'QZ Tray',
      afterLink: '.',
    },
    downloadCertificate: {
      beforeLink: 'Download ',
      link: 'dit bestand',
      beforeLocation: ' en sla het op als ',
      afterLocation: '.',
    },
    editProperties: {
      beforeLocation: 'Voeg de volgende regel toe aan eind van het bestand ',
      afterLocation: ':',
    },
    testPrinter: {
      beforeButtons: 'Test of de printer werkt:',
      printer: {
        altec: 'Altec',
        zebra: 'Zebra',
      },
    },
  },
  workSlot: {
    field: {
      monday: { label: 'Ma' },
      tuesday: { label: 'Di' },
      wednesday: { label: 'Wo' },
      thursday: { label: 'Do' },
      friday: { label: 'Vr' },
      saturday: { label: 'Za' },
      sunday: { label: 'Zo' },
    },
  },
  workCalendar: {
    scope: {
      type: {
        week: { label: 'Week' },
        month: { label: 'Maand' },
      },
    },
    slot: {
      planned: 'Ingepland',
      overtime: 'Overgewerkt',
      absent: 'Afwezig',
    },
    leaveSlotModal: {
      title: {
        add: 'Verlof Toevoegen',
        edit: 'Verlof Aanpassen',
      },
    },
    workTimesModal: {
      title: 'Werktijden {{scope}}',
    },
    total: {
      planned: '{{time}} gepland',
      actual: '{{time}} gewerkt',
      overtime: '{{time}} overgewerkt',
      absence: '{{time}} afwezig',
    },
  },
  leaveSlot: {
    field: {
      window: { label: 'Periode' },
      name: { label: 'Reden' },
      fullDay: { label: 'Volledige Dag' },
      type: {
        label: 'Type',
        value: {
          vacation: 'Vakantie',
          rwh: 'ADV',
          special: 'Bijzonder',
        },
        valueShort: {
          vacation: 'VAK',
          rwh: 'ADV',
          special: 'BIJ',
        },
        valueMinutes: {
          vacation: 'Vakantie',
          rwh: 'ADV',
          special: 'Bijzondere Verlof',
        },
      },
      status: {
        label: 'Status',
        value: {
          approved: 'Goedgekeurd',
          pending: 'In afwachting',
          rejected: 'Afgekeurd',
        },
      },
      startDate: { label: 'Startdatum' },
      endDate: { label: 'Einddatum' },
    },
    overview: {
      title: 'Verlofaanvragen',
      noneFound: 'Er zijn geen verlofaanvragen gevonden.',
      noneSelected: 'Er is nog geen verlofaanvraag geselecteerd.',
      hasComments: 'Heeft opmerkingen',
      availabilityHeader: 'Beschikbare Operator-uren',
      statusAction: {
        approved: 'Goedkeuren',
        pending: 'In behandeling nemen',
        rejected: 'Afkeuren',
      },
    },
    edit: {
      comments: {
        empty: 'Er zijn nog geen opmerkingen geplaatst.',
      },
      stats: {
        allowed: 'Totaal',
        used: 'Gebruikt',
        available: 'Beschikbaar',
        requested: 'Aangevraagd',
      },
      days: {
        legend: {
          full: 'Volledige Beschikbaarheid',
          approved: 'Indien Goedgekeurd',
          rejected: 'Indien Afgekeurd',
        },
      },
      legal: 'Wettelijk',
      extra: 'Bovenwettelijk',
    },
  },
  leaveCalendar: {
    overview: {
      title: 'Verlofkalenders',
      empty: 'Er zijn geen verlofkalenders die voldoen aan uw zoekopdracht.',
      addButton: 'Verlofkalender',
    },
    create: {
      title: 'Verlofkalender Aanmaken',
    },
    edit: {
      title: 'Verlofkalender Aanpassen',
      saveBeforeLeaveSlot: 'Je moet de verlofkalender eerst opslaan voordat je verlof toe kan voegen.',
    },
    field: {
      name: { label: 'Naam' },
      users: { label: 'Users' },
      operators: { label: 'Operators' },
      leaveSlots: { label: 'Verlof' },
    },
  },
  operatorCapability: {
    field: {
      level: {
        label: 'Level',
        value: {
          1: 'Niet vaardig',
          2: 'Lerend',
          3: 'Vaardig',
          4: 'Expert',
        },
      },
    },
  },
  process: {
    overview: {
      title: 'Processen',
      addButton: 'Proces',
      empty: 'Er zijn geen processen die voldoen aan uw zoekopdracht.',
    },
    edit: {
      newBatch: {
        label: 'Nieuwe Batch',
        info: 'Deze stap uitvoeren zal een nieuwe batch aanmaken, deze velden geven enkele details over deze batch..',
      },
      versioned: {
        label: '(versies)',
        info: 'Deze waarde is alleen van toepassing op de huidig geselecteerde versie.',
      },
      baseFields: {
        label: 'Basis Velden',
        info: 'Deze velden behoren niet tot een versie maar zijn toepasselijk op elke versie.',
      },
      batchSize: {
        label: 'Batch Grootte',
        value: {
          productionRequest: 'Werkorder',
          unit: 'Eenheid',
          batch: 'Batch van {{count}}',
        },
        error: {
          notSmaller: 'Batchgrootte moet kleiner zijn dan de voorgaande.',
          notFinal: 'Batchgrootte moet gelijk zijn aan {{expected}}.',
        },
      },
      title: 'Proces Aanpassen',
      version: 'Versie {{version}}',
      finalized: {
        label: 'Afgerond op: {{date}}',
        by: 'Afgerond door: {{user}}',
      },
      updated: {
        label: 'Bijgewerkt op: {{date}}',
        by: 'Bijgewerkt door: {{user}}',
      },
      noUpdateInfo: 'Geen informatie beschikbaar over wanneer deze versie voor het laatst is bijgewerkt.',
      draft: 'Concept',
      finalizeButton: 'Afronden',
      nextVersionButton: 'Volgende Versie',
      noStepSelected: 'Geen stap geselecteerd',
      noBatchTypeSelected: 'Geen proces geselecteerd',
      workStationsModal: {
        title: 'Selecteer Werkplekken',
      },
      tabs: {
        steps: 'Stappen',
        instructions: 'Instructies',
        articleTypes: 'Artikeltypes',
        map: 'Kaart',
      },
      addArticleTypeModal: {
        title: 'Artikeltypes Toevoegen',
        addButton: 'Toevoegen ({{count}})',
      },
      harvestDay: {
        monday: 'Ma',
        tuesday: 'Di',
        wednesday: 'Wo',
        thursday: 'Do',
        friday: 'Vr',
        saturday: 'Za',
        sunday: 'Zo',
      },
    },
    create: {
      title: 'Proces Aanmaken',
    },
    field: {
      name: { label: 'Name' },
      newestVersion: { label: 'Nieuwste Versie' },
    },
  },
  processVersion: {
    field: {
      productionLineVersion: { label: 'Productielijn' },
      batchType: { label: 'Proces' },
      version: { label: 'Versie' },
      newest: { label: 'Nieuwste' },
      factory: { label: 'Fabriek' },
    },
  },
  dataSource: {
    edit: {
      title: 'Databron Aanpassen',
    },
    create: {
      title: 'Databron Aanmaken',
    },
    overview: {
      title: 'Databronnen',
      empty: 'Er hebben zich nog geen databronnen aangemeld.',
    },
    field: {
      uuid: { label: 'UUID' },
      identifier: { label: 'Identificatiecode' },
      name: { label: 'Naam' },
      deviceName: { label: 'Apparaatnaam' },
      description: { label: 'Omschrijving' },
      workStations: { label: 'Gekoppelde werkplekken' },
      data: {
        label: 'Data',
        name: 'Naam',
        type: 'Type',
      },
    },
  },
  exactShopOrder: {
    field: {
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          released: 'Vrijgegeven',
          in_progress: 'In Bewerking',
          finalized: 'Afgerond',
          complete: 'Compleet',
        },
      },
    },
  },
  loadCarrier: {
    overview: {
      title: 'Ladingdragers',
      empty: 'Er zijn geen ladingdragers die voldoen aan uw zoekopdracht.',
      addButton: 'Ladingdrager',
      batchAddButton: 'Batch Toevoegen',
    },
    create: {
      title: 'Ladingdrager Aanmaken',
    },
    edit: {
      title: 'Ladingdrager Aanpassen',
      weighing: '{{weight}}{{unit}}, gewogen door {{user}} op {{time}}.',
      unknownUser: 'onbekend',
    },
    batchAdd: {
      title: 'Ladingdragers Batch Toevoegen',
      quantity: 'Aantal',
      serialNumberFormat: 'Serienummer Format',
    },
    field: {
      type: {
        label: 'Type',
        value: {
          pallet_euro: 'Europallet',
          pallet_pool: 'Poolpallet',
          container: 'Container',
          gitterbox: 'Gitterbox',
          carton_box: 'Kartonnen doos',
          stackbox: 'Stapel doos',
        },
      },
      serialNumber: { label: 'Serienummer' },
      name: { label: 'Naam' },
      description: { label: 'Beschrijving' },
      weight: { label: 'Gewicht' },
      width: { label: 'Breedte' },
      height: { label: 'Hoogte' },
      length: { label: 'Lengte' },
      volume: { label: 'Volume' },
      picture: { label: 'Foto' },
      batch: { label: 'Batch' },
      hasBatch: { label: 'Heeft Batch' },
      weighings: { label: 'Wegingen' },
      reweighDays: {
        label: 'Weging Interval',
        info: 'Hier kun je aangeven hoe vaak een ladingdrager gewogen moet worden. In het ladingdrager overzicht kun je dan zien wanneer een ladingdrager opnieuw gewogen moet worden en filteren op ladingdragers die gewogen moeten worden.',
        suffix: ' dagen',
      },
      reweighDate: { label: 'Herweegdatum' },
      needsReweighing: { label: 'Moet Herwogen Worden' },
    },
  },
  login: {
    workstation: 'Werkstation code',
    barcode: 'Artikel barcode',
    title: 'Tracy Picker',
    username: 'Gebruiker',
    batchcode: 'Batchcode',
    notAllowed: 'Dit is geen geldige werkstation code',
    password: 'Wachtwoord',
    forgotPassword: 'Wachtwoord vergeten?',
    clearusername: 'Wissen gebruiker',
    clearworkstation: 'Wissen werkstation code',
    clearprinter: 'Printer gegevens wissen',
    forgotMailSend: 'Email verstuurd',
    successful: 'Succesvol ingelogd',
    logIn: 'Log In',
    error: 'Fout bij inloggen',
    wrong_credentials: 'Geen geldige badge code',
  },
  shipping: {
    picture: 'foto van de lading',
    picture_accept: 'Accepteer foto',
    picture_decline: 'Foto opnieuw nemen',
    packed: 'Verpakken afronden',
    start_send: 'Start verzendproces',
    start_receive: 'Start ontvangstproces',
    send_shipment: 'Verstuur de levering',
    receive_shipment: 'Ontvang levering',
  },
  articleInfo: {
    clearBarcode: 'Barcode wissen',
    search: 'Zoek naar barcode',
    wrongCode: 'Kon barcode niet vinden in het system',
    notAllowed: 'Deze gebruiker mag dit product niet bekijken',
    error: 'Fout tijdens het zoeken naar de barcode',
  },
  shipment: {
    articleInfo: {
      performanceLine:
        '{{stepLabel}} van process {{productionLineName}} uitgevoerd om {{performanceTime}} door {{performanceBy}}',
      batchType: 'Batch Type',
      batchDescription: 'Batch Omschrijving',
      batchSize: 'Batch grootte',
      articleNameAndCode: 'Artikel naam en Exact code',
      scannedBarcode: 'Gescande Barcode',
    },
    picker: {
      default: 'Selecteer een waarde',
    },
    shipmentInfo: 'Order informatie',
    status: {
      picking: 'Picking',
      printing: 'Printen',
      sending: 'Versturen',
      sent: 'Verzonden',
      toReceive: 'Te Ontvangen',
      received: 'Ontvangen en verwerkt',
    },
    field: {
      salesOrder: {
        label: 'Verkooporder',
      },
      purchaseOrder: { label: 'Inkooporder' },
      receiveDate: { label: 'Geplande leverdatum' },
      receivedTime: { label: 'Levertijd' },
      shippingDate: {
        label: 'Verstuur datum',
      },
      freightType: {
        label: 'Vrachtsoort',
        value: {
          road: 'Land',
        },
      },
      freightCompany: {
        label: 'TransportBedrijf',
      },
      shippingName: {
        label: 'Naam chauffeur',
      },
      shippingLicense: {
        label: 'Kenteken vrachtwagen',
      },
      shippingTime: {
        label: 'Tijd van versturen',
      },
      quantity: {
        label: 'Aantal',
      },
      batchSize: {
        label: 'Batch grootte',
      },
    },
    outbound: {
      pickitem: 'Pick artikelen',
      printreceipt: 'Afdrukken pakbon',
      package: 'Verpakken',
      printshipmentlabel: 'Afdrukken verzendetiket',
      send: 'Versturen',
    },
    inbound: {
      receiveItem: 'Ontvang bestelling',
      barcodeCreation: 'Identificeren producten',
      warehousePlacement: 'Plaatsing in het magazijn',
    },
    heading: {
      cmrLines: 'CMR LINES',
    },
    titleInbound: 'Ontvangstorder {{id}}',
    titleOutbound: 'Verzendorder {{id}}',
    confirmShipment: 'Weet je zeker dat je {{custName}} een zending wilt sturen met ordernummer {{trackingNumber}}?',
    confirmReceive:
      'Weet je zeker dat je van {{custName}} een zending wilt accepteren met ordernummer {{trackingNumber}}?',
    sendShipmentTitle: 'Versturen',
    receiveShipmentTitle: 'Ontvangen',
    scan: 'Scan',
    leftToPick: 'Te picken',
    actualyPicked: 'Picked',
    documents: {
      label: 'Documenten',
      packingListRequired: 'Pakbon',
      invoiceRequired: 'Factuur',
      eur1Required: 'EUR1',
      cvoRequired: 'C/O',
    },
    tab: {
      shipmentLines: 'Artikelen',
      info: 'Info',
    },
    noCmrLines: 'Deze verzending heeft geen verzendregels.',
    box: 'Doos {{id}}',
    buyBox: 'Doos',
    hiddenBoxes: '{{count}} Dozen',
    error: {
      wrongArticle: 'Verkeerd artikel.',
      alreadyScanned: 'Doos al gescand.',
      alreadyShipped: 'Doos al gebruikt in andere order.',
      tooManyProducts: 'Deze doos bevat teveel producten.',
      invalidQuantity: 'Niet bruikbare hoeveelheid voor dit aantal.',
    },
  },
  common: {
    article: {
      field: {
        code: { label: 'Code' },
        name: { label: 'Naam' },
      },
    },
    shipmentLine: {
      field: {
        quantity: { label: 'Aantal' },
      },
    },
    shipment: {
      field: {
        salesOrder: { label: 'Verkooporder' },
        purchaseOrder: { label: 'Inkooporder' },
        shippingDate: { label: 'Verzenddatum' },
        receiveDate: { label: 'Geplande leverdatum' },
        shippingName: { label: 'Naam' },
        shippingTime: { label: 'Verzend tijd' },
        receivedTime: { label: 'Ontvangst tijd' },
        shippingLicense: { label: 'Kenteken' },
        freight: {
          label: 'Vrachtsoort',
          value: {
            road: 'Weg',
            air: 'Lucht',
            sea: 'Zee',
            parcel: 'Express',
          },
        },
        freightCompany: { label: 'Transporteur' },
      },
    },
    cmrLine: {
      field: {
        mark: { label: 'Regel' },
        number: { label: 'Nummmer' },
        method: { label: 'Methode' },
        nature: { label: 'Aard' },
        weight: { label: 'Gewicht' },
        cubage: { label: 'Inhoud' },
      },
    },
    loading: 'Laden...',
    error: {
      somethingWentWrong: 'Er ging iets verkeerd.',
    },
  },
  workTime: {
    field: {
      date: { label: 'Datum' },
      startTime: { label: 'Begintijd' },
      endTime: { label: 'Eindtijd' },
      automaticallyClockedOut: { label: 'Automatisch uitgeklokt' },
    },
  },
  softdeleted: {
    label: 'Verwijderde items tonen',
    value: {
      true: 'ja',
      false: 'nee',
      only: 'alleen',
    },
  },
  logistics: {
    erpCounts: {
      header: {
        date: 'Datum',
        quantity: '#',
      },
    },
  },
  extraLeaveBalance: {
    field: {
      year: { label: 'Van toepassing op (jaar)' },
      legalLeaveMinutes: { label: 'Wettelijk' },
      extraLeaveMinutes: { label: 'Bovenwettelijk' },
      rwhMinutes: { label: 'ADV' },
    },
  },
  formField: {
    field: {
      slug: { label: 'Print slug' },
      articleType: {
        self: '(Dynamisch)',
        selfInfo: 'Hierbij wordt automatisch het artikeltype ingevuld waar deze template gebruikt wordt.',
      },
      type: {
        quantityInfo: 'Dit type kan gebruikt worden om een precieze grootte te zetten op een variabele batch. Je kunt ook een gewicht per eenheid ingeven, in dit geval zal de grootte berekend worden aan de hand van een gewicht dat de operator invoert.',
      },
      measureMin: { label: 'Minimum' },
      measureMax: { label: 'Maximum' },
      quantityUnitWeight: { label: 'Gewicht per eenheid' },
      articleTypeBatchSize: { label: 'Batchmaat?' },
      required: { label: 'Vereist' },
    },
  },
  integration: {
    overview: {
      title: 'Integraties',
      empty: 'Geen integraties gevonden.',
      addButton: 'Integratie',
      inactive: 'Deze integratie is niet actief.',
    },
    create: {
      title: 'Integratie Aanmaken',
    },
    edit: {
      title: 'Integratie Aanpassen',
    },
    field: {
      type: {
        label: 'Type',
        value: {
          exact: 'Exact',
          unit4: 'Unit 4',
        },
      },
      name: { label: 'Naam' },
      syncErrorCount: { label: 'Synchronisatie Fouten' },
    },
    syncmodal: {
      title: 'Synchroniseer',
      syncButton: 'Sync',
      pending: 'In wachtrij',
      from_db: 'Cached',
      from_inline: 'Gedeeltelijk',
      created: 'Aangemaakt',
      updated: 'Bijgewerkt',
      requested: 'Aangevraagd',
      dependencies: 'Gelinkte objecten',
      measureMin: { label: 'Min' },
      measureMax: { label: 'Max' },
      quantityUnitWeight: { label: 'Gewicht per Eenheid' },
    },
    syncers: {
      exact_account_syncer: 'Accounts',
      exact_address_syncer: 'Addressen',
      exact_item_syncer: 'Items',
      exact_project_syncer: 'Projecten',
      exact_purchase_order_syncer: 'Verkoop orders',
      exact_sales_order_syncer: 'Verkoop orders',
      exact_warehouse_syncer: 'Magazijnen',
      exact_storage_location_syncer: 'Opslaglocaties',
      exact_item_warehouse_syncer: 'Voorraden (per magazijn)',
      exact_item_warehouse_location_syncer: 'Voorraden (per locatie)',
      exact_goods_receipt_syncer: 'Inkoopbonnen',
      exact_warehouse_transfer_syncer: 'Magazijnverplaatsingen',
      exact_stock_position_syncer: 'Voorraad update',
    },
  },
  exactIntegration: {
    overview: {
      authButton: 'Activeer Exact Integratie',
      authResult: {
        ok: 'Exact integratie succesvol geactiveerd.',
        error: {
          invalid_request: 'Onjuist verzoek verzonden naar Exact.',
          invalid_client: 'Onjuiste client verzonden naar Exact.',
          invalid_grant: 'Onjuiste grant verzonden naar Exact.',
          invalid_scope: 'Onjuiste scope verzonden naar Exact. ',
          unauthorized_client: 'Ongeauthorizeerde client verzonden naar Exact.',
          unsupported_grant_type: 'Niet ondersteund grant type verzonden naar Exact.',
        },
      },
      syncButton: 'Synchroniseer met Exact',
      syncResult: {
        authFailure: { notification: 'Authenticatie met Exact gefaald.' },
        success: {
          notification: 'Succesvol gesynchroniseerd met Exact',
          count: {
            address: {
              tracy: {
                created: '{{count}} adres in Tracy aangemaakt.',
                created_plural: '{{count}} adressen in Tracy aangemaakt.',
                updated: '{{count}} adres in Tracy bijgewerkt.',
                updated_plural: '{{count}} adressen in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} address in Exact aangemaakt.',
                created_plural: '{{count}} adressen in Exact aangemaakt.',
                updated: '{{count}} address in Exact bijgewerkt.',
                updated_plural: '{{count}} adressen in Exact bijgewerkt.',
              },
            },
            item: {
              tracy: {
                created: '{{count}} artikel in Tracy aangemaakt.',
                created_plural: '{{count}} artikelen in Tracy aangemaakt.',
                updated: '{{count}} artikel in Tracy bijgewerkt.',
                updated_plural: '{{count}} artikelen in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} artikel in Exact aangemaakt.',
                created_plural: '{{count}} artikelen in Exact aangemaakt.',
                updated: '{{count}} artikel in Exact bijgewerkt.',
                updated_plural: '{{count}} artikelen in Exact bijgewerkt.',
              },
            },
            project: {
              tracy: {
                created: '{{count}} project in Tracy aangemaakt.',
                created_plural: '{{count}} projecten in Tracy aangemaakt.',
                updated: '{{count}} project in Tracy bijgewerkt.',
                updated_plural: '{{count}} projecten in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} project in Exact aangemaakt.',
                created_plural: '{{count}} projecten in Exact aangemaakt.',
                updated: '{{count}} project in Exact bijgewerkt.',
                updated_plural: '{{count}} projecten in Exact bijgewerkt.',
              },
            },
            storage_location_stock_position: {
              tracy: {
                created: '{{count}} opslaglocatie in Tracy aangemaakt.',
                created_plural: '{{count}} opslaglocaties in Tracy aangemaakt.',
                updated: '{{count}} opslaglocatie in Tracy bijgewerkt.',
                updated_plural: '{{count}} opslaglocaties in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} opslaglocatie in Exact aangemaakt.',
                created_plural: '{{count}} opslaglocaties in Exact aangemaakt.',
                updated: '{{count}} opslaglocatie in Exact bijgewerkt.',
                updated_plural: '{{count}} opslaglocaties in Exact bijgewerkt.',
              },
            },
            bill_of_material: {
              tracy: {
                created: '{{count}} stuklijst in Tracy aangemaakt.',
                created_plural: '{{count}} stuklijsten in Tracy aangemaakt.',
                updated: '{{count}} stuklijst in Tracy bijgewerkt.',
                updated_plural: '{{count}} stuklijsten in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} stuklijst in Exact aangemaakt.',
                created_plural: '{{count}} stuklijsten in Exact aangemaakt.',
                updated: '{{count}} stuklijst in Exact bijgewerkt.',
                updated_plural: '{{count}} stuklijsten in Exact bijgewerkt.',
              },
            },
            bill_of_material_item: {
              tracy: {
                created: '{{count}} stuklijst artikel in Tracy aangemaakt.',
                created_plural: '{{count}} stuklijst artikelen in Tracy aangemaakt.',
                updated: '{{count}} stuklijst artikel in Tracy bijgewerkt.',
                updated_plural: '{{count}} stuklijst artikelen in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} stuklijst artikel in Exact aangemaakt.',
                created_plural: '{{count}} stuklijst artikelen in Exact aangemaakt.',
                updated: '{{count}} stuklijst artikel in Exact bijgewerkt.',
                updated_plural: '{{count}} stuklijst artikelen in Exact bijgewerkt.',
              },
            },
            account: {
              tracy: {
                created: '{{count}} relatie in Tracy aangemaakt.',
                created_plural: '{{count}} relaties in Tracy aangemaakt.',
                updated: '{{count}} relatie in Tracy bijgewerkt.',
                updated_plural: '{{count}} relaties in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} relatie in Exact aangemaakt.',
                created_plural: '{{count}} relaties in Exact aangemaakt.',
                updated: '{{count}} relatie in Exact bijgewerkt.',
                updated_plural: '{{count}} relaties in Exact bijgewerkt.',
              },
            },
            sales_order: {
              tracy: {
                created: '{{count}} verkooporder in Tracy aangemaakt.',
                created_plural: '{{count}} verkooporders in Tracy aangemaakt.',
                updated: '{{count}} verkooporder in Tracy bijgewerkt.',
                updated_plural: '{{count}} verkooporders in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} verkooporder in Exact aangemaakt.',
                created_plural: '{{count}} verkooporders in Exact aangemaakt.',
                updated: '{{count}} verkooporder in Exact bijgewerkt.',
                updated_plural: '{{count}} verkooporders in Exact bijgewerkt.',
              },
            },
            purchase_order: {
              tracy: {
                created: '{{count}} inkooporder in Tracy aangemaakt.',
                created_plural: '{{count}} inkooporders in Tracy aangemaakt.',
                updated: '{{count}} inkooporder in Tracy bijgewerkt.',
                updated_plural: '{{count}} inkooporders in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} inkooporder in Exact aangemaakt.',
                created_plural: '{{count}} inkooporders in Exact aangemaakt.',
                updated: '{{count}} inkooporder in Exact bijgewerkt.',
                updated_plural: '{{count}} inkooporders in Exact bijgewerkt.',
              },
            },
            shop_order: {
              tracy: {
                created: '{{count}} productie order in Tracy aangemaakt.',
                created_plural: '{{count}} productie orders in Tracy aangemaakt.',
                updated: '{{count}} productie order in Tracy bijgewerkt.',
                updated_plural: '{{count}} productie orders in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} productie order in Exact aangemaakt.',
                created_plural: '{{count}} productie orders in Exact aangemaakt.',
                updated: '{{count}} productie order in Exact bijgewerkt.',
                updated_plural: '{{count}} productie orders in Exact bijgewerkt.',
              },
            },
            shop_order_material_plan: {
              tracy: {
                created: '{{count}} productie order materiaal plan in Tracy aangemaakt.',
                created_plural: '{{count}} productie order materiaal plans in Tracy aangemaakt.',
                updated: '{{count}} productie order materiaal plan in Tracy bijgewerkt.',
                updated_plural: '{{count}} productie order materiaal plans in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} productie order materiaal plan in Exact aangemaakt.',
                created_plural: '{{count}} productie order materiaal plans in Exact aangemaakt.',
                updated: '{{count}} productie order materiaal plan in Exact bijgewerkt.',
                updated_plural: '{{count}} productie order materiaal plans in Exact bijgewerkt.',
              },
            },
            shop_order_receipt: {
              tracy: {
                created: '{{count}} productie order ontvangst in Tracy aangemaakt.',
                created_plural: '{{count}} productie order ontvangsten in Tracy aangemaakt.',
                updated: '{{count}} productie order ontvangst in Tracy bijgewerkt.',
                updated_plural: '{{count}} productie order ontvangsten in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} productie order ontvangst in Exact aangemaakt.',
                created_plural: '{{count}} productie order ontvangsten in Exact aangemaakt.',
                updated: '{{count}} productie order ontvangst in Exact bijgewerkt.',
                updated_plural: '{{count}} productie order ontvangsten in Exact bijgewerkt.',
              },
            },
            shop_order_reversal: {
              tracy: {
                created: '{{count}} productie order herroeping in Tracy aangemaakt.',
                created_plural: '{{count}} productie order herroepingen in Tracy aangemaakt.',
                updated: '{{count}} productie order herroeping in Tracy bijgewerkt.',
                updated_plural: '{{count}} productie order herroepingen in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} productie order herroeping in Exact aangemaakt.',
                created_plural: '{{count}} productie order herroepingen in Exact aangemaakt.',
                updated: '{{count}} productie order herroeping in Exact bijgewerkt.',
                updated_plural: '{{count}} productie order herroepingen in Exact bijgewerkt.',
              },
            },
            opportunity: {
              tracy: {
                created: '{{count}} kans in Tracy aangemaakt.',
                created_plural: '{{count}} kansen in Tracy aangemaakt.',
                updated: '{{count}} kans in Tracy bijgewerkt.',
                updated_plural: '{{count}} kansen in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} kans in Exact aangemaakt.',
                created_plural: '{{count}} kansen in Exact aangemaakt.',
                updated: '{{count}} kans in Exact bijgewerkt.',
                updated_plural: '{{count}} kansen in Exact bijgewerkt.',
              },
            },
            quotation: {
              tracy: {
                created: '{{count}} offerte in Tracy aangemaakt.',
                created_plural: '{{count}} offertes in Tracy aangemaakt.',
                updated: '{{count}} offerte in Tracy bijgewerkt.',
                updated_plural: '{{count}} offertes in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} offerte in Exact aangemaakt.',
                created_plural: '{{count}} offertes in Exact aangemaakt.',
                updated: '{{count}} offerte in Exact bijgewerkt.',
                updated_plural: '{{count}} offertes in Exact bijgewerkt.',
              },
            },
            goods_receipt: {
              tracy: {
                created: '{{count}} goederen ontvangst in Tracy aangemaakt.',
                created_plural: '{{count}} goederen ontvangsten in Tracy aangemaakt.',
                updated: '{{count}} goederen ontvangst in Tracy bijgewerkt.',
                updated_plural: '{{count}} goederen ontvangsten in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} goederen ontvangst in Exact aangemaakt.',
                created_plural: '{{count}} goederen ontvangsten in Exact aangemaakt.',
                updated: '{{count}} goederen ontvangst in Exact bijgewerkt.',
                updated_plural: '{{count}} goederen ontvangsten in Exact bijgewerkt.',
              },
            },
            goods_delivery: {
              tracy: {
                created: '{{count}} goederen levering in Tracy aangemaakt.',
                created_plural: '{{count}} goederen leveringen in Tracy aangemaakt.',
                updated: '{{count}} goederen levering in Tracy bijgewerkt.',
                updated_plural: '{{count}} goederen leveringen in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} goederen levering in Exact aangemaakt.',
                created_plural: '{{count}} goederen leveringen in Exact aangemaakt.',
                updated: '{{count}} goederen levering in Exact bijgewerkt.',
                updated_plural: '{{count}} goederen leveringen in Exact bijgewerkt.',
              },
            },
            sales_return: {
              tracy: {
                created: '{{count}} verkoop retour in Tracy aangemaakt.',
                created_plural: '{{count}} verkoop retouren in Tracy aangemaakt.',
                updated: '{{count}} verkoop retour in Tracy bijgewerkt.',
                updated_plural: '{{count}} verkoop retouren in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} verkoop retour in Exact aangemaakt.',
                created_plural: '{{count}} verkoop retouren in Exact aangemaakt.',
                updated: '{{count}} verkoop retour in Exact bijgewerkt.',
                updated_plural: '{{count}} verkoop retouren in Exact bijgewerkt.',
              },
            },
            warehouse_transfer: {
              tracy: {
                created: '{{count}} voorraad verplaatsing in Tracy aangemaakt.',
                created_plural: '{{count}} voorraad verplaatsingen in Tracy aangemaakt.',
                updated: '{{count}} voorraad verplaatsing in Tracy bijgewerkt.',
                updated_plural: '{{count}} voorraad verplaatsingen in Tracy bijgewerkt.',
              },
              exact: {
                created: '{{count}} voorraad verplaatsing in Exact aangemaakt.',
                created_plural: '{{count}} voorraad verplaatsingen in Exact aangemaakt.',
                updated: '{{count}} voorraad verplaatsing in Exact bijgewerkt.',
                updated_plural: '{{count}} voorraad verplaatsingen in Exact bijgewerkt.',
              },
            },
            stock_count: {
              exact: {
                created: '{{count}} voorraadtelling in Exact aangemaakt.',
                created_plural: '{{count}} voorraadtellingen in Exact aangemaakt.',
              },
            },
          },
        },
      },
    },
    field: {
      division: { label: 'Divisie' },
      manageEndpointsHeader: { label: 'Beheer EXACT API-eindpunten' },
      address: { label: 'Adres' },
      item: { label: 'Item' },
      account: { label: 'Account' },
      salesOrder: { label: 'Verkooporder' },
      purchaseOrder: { label: 'Bestelling' },
      project: { label: 'Project' },
      billOfMaterial: { label: 'Stuklijst' },
      billOfMaterialItem: { label: 'Stuklijstitem' },
      shopOrder: { label: 'Werkorder' },
      shopOrderMaterialPlan: { label: 'Materiaalplan' },
      shopOrderReceipt: { label: 'Werkorder ontvangst' },
      shopOrderReversal: { label: 'Winkelbestelling ongedaan maken' },
      salesReturn: { label: 'Verkoopretour' },
      warehouse: { label: 'Magazijn' },
      storageLocationStockPosition: { label: 'Voorraadpositie Opslaglocatie' },
      itemWarehouse: { label: 'Magazijn Artikel' },
      goodsReceipt: { label: 'Goederenontvangst' },
      goodsDelivery: { label: 'Goederenaflevering' },
      warehouseTransfer: { label: 'Voorraad verplaatsing' },
      stockCount: { label: 'Voorraad telling' },
      stockCountReversal: { label: 'Omkering voorraad telling' },
      stockTopic: { label: 'Voorraad' },
    },
    edit: {
      topics: {
        title: 'Beheer Exact API-eindpunten',
        subtitle: {
          general: 'Algemeen',
          production: 'Productie',
          warehouse: 'Magazijn',
        },
      },
    },
  },
  unit4Integration: {
    overview: {
      authButton: 'Activeer Unit4 Integratie',
      authResult: {
        ok: 'Unit4 integratie succesvol geactiveerd.',
        error: {
          invalid_request: 'Onjuist verzoek verzonden naar Unit4.',
          invalid_client: 'Onjuiste client verzonden naar Unit4.',
          invalid_grant: 'Onjuiste grant verzonden naar Unit4.',
          invalid_scope: 'Onjuiste scope verzonden naar Unit4. ',
          unauthorized_client: 'Ongeauthorizeerde client verzonden naar Unit4.',
          unsupported_grant_type: 'Niet ondersteund grant type verzonden naar Unit4.',
        },
      },
      syncButton: 'Synchroniseer met Unit4',
      syncResult: {
        authFailure: { notification: 'Authenticatie met Exact gefaald.' },
        success: {
          notification: 'Succesvol gesynchroniseerd met Unit4',
        },
      },
    },
    field: {
      administration: { label: 'Administratiecode' },
      baseUrl: { label: 'Unit 4 server basis-URL' },
      manageEndpointsHeader: { label: 'Beheer Unit4 API-eindpunten' },
      goodsReceipt: { label: 'Goods Receipt' },
      product: { label: 'Product' },
      productDetails: { label: 'Product Details' },
      supplier: { label: 'Supplier' },
      purchaseOrder: { label: 'Purchase Order' },
      customer: { label: 'Customer' },
      order: { label: 'Order' },
    },
  },
  syncError: {
    overview: {
      title: 'Synchronisatie Fouten',
      empty: 'Geen snchronisatie fouten gevonden.',
    },
    field: {
      id: { label: 'ID' },
      message: { label: 'Melding' },
    },
  },
  project: {
    overview: {
      title: 'Projecten',
      empty: 'Er zijn geen projecten die voldoen aan uw zoekopdracht.',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      description: { label: 'Beschrijving' },
      notes: { label: 'Notities' },
      startDate: { label: 'Start Datum' },
      endDate: { label: 'Eind Datum' },
    },
  },
  billOfMaterialVersion: {
    edit: {
      title: 'Bekijk stuklijst',
    },
    field: {
      version: {
        label: 'Versie',
      },
      status: {
        label: 'Status',
      },
      description: {
        label: 'Omschrijving',
      },
      default: {
        label: 'Standaard',
      },
    },
  },
  billOfMaterialItem: {
    field: {
      articleType: { label: 'Artikel' },
      description: { label: 'Omschrijving' },
      quantity: { label: 'Aantal' },
      required: { label: 'Benodigd' },
      assigned: { label: 'Toegekend' },
      backflush: { label: 'Backflush' },
      number: { label: 'Regel.' },
      warehouse: { label: 'Magazijn' },
      storageLocations: { label: 'Opslaglocaties' }
    },
  },
  productionOrder: {
    field: {
      billOfMaterialVersion: { label: 'Stuklijst Versie' },
      warehouse: { label: 'Magazijn' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          released: 'Vrijgegeven',
          finished: 'Klaar',
        },
      },
    },
    billOfMaterialVersionPlaceholder: 'Geen stuklijst',
    procesPlaceholder: 'Geen maak proces',
    edit: {
      title: 'Productie Order Aanpassen',
    },
    create: {
      title: 'Productie Order Aanmaken',
    },
  },
  script: {
    overview: {
      title: 'Scripts',
      empty: 'Er zijn geen scripts die voldoen aan uw zoekopdracht.',
      addButton: 'Script',
    },
    create: {
      title: 'Script Aanmaken',
    },
    edit: {
      title: 'Script Aanpassen',
      schedule: 'Dag Schema',
      copyPaste: {
        copyButton: 'Kopieëren',
        pasteButton: 'Plakken',
        copied: 'Succesvol gekopieerd',
        pasted: 'Succesvol geplakt',
        pasteError: 'Niks om te plakken',
        pasteTypeMismatch: 'Klembord bevat een script van een ander type',
      },
    },
    field: {
    notes: { label: 'Notities' },
      status: {
        label: 'Status',
        value: {
          concept: 'Concept',
          test: 'Test',
          production: 'Productie',
          archived: 'Gearchiveerd',
        },
      },
      type: {
        label: 'Type',
        value: {
          environment: 'Klimaat',
          light: 'Licht',
          water: 'Water',
          nutrition: 'Voeding',
        },
      },
      name: { label: 'Naam' },
    },
  },
  workStationSession: {
    overview: {
      title: 'Werkplek Sessies',
      empty: 'Geen werkplek sessies gevonden.',
      viewPrintJobsButton: 'Bekijk printopdrachten',
    },
    field: {
      id: { label: 'ID' },
      type: {
        label: 'Type',
        value: {
          desktop: 'Desktop',
          mobile: 'Mobiel',
        },
      },
      printers: {
        label: 'Printers',
        empty: 'Geem printers beschikbaar.',
      },
      workStation: { label: 'Werkplek' },
      appVersion: { label: 'App Versie' },
      isActive: { label: 'Is Actief' },
      createdAt: { label: 'Gestart op' },
      updatedAt: { label: 'Laatste activiteit' },
      printJobs: { label: 'Print Opdrachten' },
    },
  },
  printJob: {
    field: {
      type: {
        label: 'Type',
        value: {
          altec: 'Altec',
          zebra: 'Zebra',
          document: 'Document',
        },
      },
      status: {
        label: 'Status',
        value: {
          todo: 'Te doen',
          done: 'Afgerond',
          failed: 'Mislukt',
        },
      },
      content: {
        label: 'Inhoud',
      },
      copies: {
        label: 'Kopieën',
      },
      createdAt: {
        label: 'Toegevoegd op',
      },
    }
  },
  warehouseTransfer: {
    overview: {
      title: 'Magazijnverplaatsing',
      empty: 'Geen magazijnverplaatsingen',
      viewLinesButton: 'Bekijk magazijnverplaatsing sregels',
    },
    edit: {
      title: 'Magazijnverplaatsing bewerken',
    },
    create: {
      title: 'Magazijnverplaatsing toevoegen',
    },
    field: {
      id: { label: 'ID' },
      description: { label: 'Beschrijving' },
      entryDate: { label: 'Invoerdatum' },
      plannedDeliveryDate: { label: 'Geplande leverdatum' },
      completedOnDate: { label: 'Voltooid op datum' },
      status: {
        label: 'Toestand',
        value: {
          draft: 'Concept',
          processed: 'Verwerkt',
        },
      },
      transferLines: {
        label: 'Magazijnverplaatsing regel',
        empty: 'Nog geen magazijnverplaatsing regels toegevoegd.',
        value: 'Magazijnverplaatsing regel {{id}}',
      },
      source: {
        label: 'Bron',
        value: {
          manual_entry: 'Handmatige invoer',
          import: 'Importeren',
          transfer_advice: 'Transfer advies',
          web_service: 'Webservice',
        },
      },
      warehouseFrom: {
        label: 'Magazijn van',
      },
      warehouseTo: {
        label: 'Magazijn naar',
      },
      type: {
        label: 'Overdrachtstype',
        value: {
          true: 'locatie',
          false: 'Magazijn',
        }
      },
      productionRequest: {
        label: 'Werkorder',
        isNull: 'Met werkorders',
      },
      transferLines: {
        label: 'Magazijnoverdrachtlijnen',
        value: 'Transferlijn {{id}}',
        empty: 'Er zijn geen magazijnoverboekingsregels toegevoegd.',
      },
    },
  },
  warehouseTransferLine: {
    empty: 'Geen vooraadverplaatsingsregels.',
    field: {
      id: { label: 'ID' },
      lineNumber: { label: 'Regelnummer' },
      createdDate: { label: 'Aanmaakdatum' },
      quantity: { label: 'Aantal stuks' },
      articleType: { label: 'Artikeltype' },
      availableStock: { label: 'Voorraad' },
      noAvailableStock: { label: 'Geen vooraad voor geselecteerd artikeltype.' },
      storageLocation: {
        id: { label: 'ID' },
        warehouse: { label: 'Magazijn' },
        code: { label: 'Code' },
        stock: { label: 'Voorraad' },
      },
      storageLocationFrom: {
        label: 'Van opslaglocatie',
      },
      storageLocationTo: {
        label: 'Naar opslaglocatie',
      },
      articleType: {
        label: 'Artikeltype',
      },
      warehouseTransfer: {
        label: 'Voorraadverplaatsing',
      },
    },
    create: {
      title: 'Bekijk Voorraadverplaatsingsregels',
    },
  },
  detailImageModal: {
    title: {label: 'Geüploade afbeeldingen'},
    popup: {label: 'Bekijk geüploade afbeeldingen'}
  },
  stockCount: {
    field: {
      warehouse: { label: 'Magazijn' },
      defaultStorageLocation: { label: 'Opslaglocatie' },
    },
    edit: {
      title: 'Voorraadtelling aanpassen'
    },
    title: { label: 'Geüploade afbeeldingen' },
    popup: { label: 'Bekijk geüploade afbeeldingen' }
  },
  rampUpTarget: {
    field: {
      date: { label: 'Datum' },
      processes: { label: 'Processen' },
      capacity: { label: 'Max Capaciteit' },
      maxOperators: { label: 'Max Operators' },
      maxHarvest: { label: 'Max Dagoogst' },
    },
  },
  rampUpAutomation: {
    field: {
      workStation: { label: 'Werkstation' },
      date: { label: 'Automatiseringsdatum' },
    },
  },
  growStep: {
    field: {
      days: { label: 'Dagen' },
      color: {
        label: 'Kleur',
        value: {
          blue: 'Blauw',
          purple: 'Paars',
          red: 'Rood',
          orange: 'Oranje',
          green: 'Groen',
        },
      },
      climateScript: { label: 'Klimaat Script' },
      lightScript: { label: 'Licht Script' },
      waterScript: { label: 'Water Script' },
      plantationLine: { label: 'Plantageomgeving' },
    },
  },
  rampUp: {
    overview: {
      title: 'Scenarios',
      empty: 'Geen scenarios gevonden.',
      addButton: 'Scenario',
    },
    create: {
      title: 'Scenario Toevoegen',
    },
    edit: {
      title: 'Scenario Bewerken',
      schedule: 'Oogst Schema',
      automation: 'Automatiseringsdatums',
      noLimit: 'Geen Limiet',
      graph: {
        label: 'Grafiek',
        value: {
          capacity: 'Capaciteit',
          operators: 'Operators',
          harvest: 'Oogst',
        },
      },
      copyPaste: {
        copyButton: 'Kopiëren',
        pasteButton: 'Plakken',
        copied: 'Succesvol gekopieerd',
        pasted: 'Succesvol geplakt',
        pasteError: 'Niks om te plakken',
      },
    },
    field: {
      name: { label: 'Naam' },
      articles: {
        label: 'Artikelen',
        empty: 'Nog geen artikelen toegevoegd',
        add: 'Artikel Toevoegen',
      },
      capacity: { label: 'Totaal Containers' },
      monday: { label: 'Maandag' },
      tuesday: { label: 'Dinsdag' },
      wednesday: { label: 'Woensdag' },
      thursday: { label: 'Donderdag' },
      friday: { label: 'Vrijdag' },
      saturday: { label: 'Zaterdag' },
      sunday: { label: 'Zondag' },
    },
  },
  operatorAssignment: {
    field: {
      workStation: { label: 'Werkstatoin' },
      operator: { label: 'Operator' },
      startAt: { label: 'Start' },
      endAt: { label: 'Einde' },
    },
  },
  articleTypeStorageLocation: {
    field: {
      isDefault: { label: 'Standaard' },
      stock: { label: '# beschikbaar' },
      assigned: { label: '# toegekend' },
      warehouse: { label: 'Magazijn' },
    },
  },
  storageLocation: {
    field: {
      code: { label: 'Code' },
      name: { label: 'Name' },
    },
  },
  waterScript: {
    field: {
      nutritionScript: { label: 'Voeding' },
      ec: { label: 'EC' },
      ecMin: { label: 'EC min' },
      ecMax: { label: 'EC max' },
      ecT: { label: 'EC t' },
      ph: { label: 'pH' },
      phMin: { label: 'pH min' },
      phMax: { label: 'pH max' },
      phT: { label: 'pH t' },
      pulse: { label: 'Puls' },
      cycle: { label: 'Cyclus' },
      flowMin: { label: 'Flow min' },
      flowMax: { label: 'Flow max' },
    },
  },
  plantationLine: {
    overview: {
      title: 'Plantageomgevingen',
      empty: 'Geen plantageomgevingen gevonden',
      addButton: 'Plantageomgeving',
      details: 'Details',
      filters: 'Filters',
      rack: { label: 'Rek', value: '{{rack}}' },
      location: { label: 'Positie', value: 'R{{rack}}L{{layer}}P{{position}}' },
      container: { label: 'Container' },
      time: {
        start: 'Start',
        end: 'Eind',
      },
      scripts: {
        label: 'Waardes',
        setpoint: {
          name: 'Naam',
          value: 'Waarde',
        },
      },
    },
    create: {
      title: 'Plantageomgeving Toevoegen',
    },
    edit: {
      title: 'Plantageomgeving Aanpassen',
    },
  },
  plantationAllocation: {
    field: {
      startDate: { label: 'Start' },
      endDate: { label: 'Eind' },
    },
  },
  nutritionScript: {
    field: {
      nitrogen: { label: 'Stikstof (N)' },
      phosphorus: { label: 'Fosfor (P)' },
      potassium: { label: 'Potassium (K)' },
      calcium: { label: 'Calcium (Ca)' },
      magnesium: { label: 'Magnesium (Mg)' },
      sulphur: { label: 'Zwavel (S)' },
    },
  },
  lightScript: {
    field: {
      intensity: { label: 'Intensiteit' },
      startTime: { label: 'Tijd' },
    },
  },
  lightScriptSchedule: {
    field: {
      intensity: { label: 'Intensiteit' },
      startTime: { label: 'Tijd' },
    },
  },
  factory: {
    overview: {
      title: 'Fabrieken',
      empty: 'Geen fabrieken gevonden',
      addButton: 'Fabriek',
    },
    create: {
      title: 'Fabriek Toevoegen',
    },
    edit: {
      title: 'Fabriek Aanpassen',
    },
    field: {
      name: { label: 'Naam' },
      timezone: { label: 'Tijdzone' },
      currentTime: { label: 'Huidige Tijd' },
    },
  },
  environmentScript: {
    field: {
      direction: {
        label: 'Richting',
        value: {
          a_to_b: 'A naar B',
          b_to_a: 'B naar A',
        },
      },
      speed: { label: 'Snelheid' },
      tIn: { label: 'T in' },
      deltaT: { label: 'ΔT' },
      deltaX: { label: 'ΔX' },
      co2In: { label: 'CO² in' },
      startTime: { label: 'Tijd' },
    },
  },
  environmentScriptSchedule: {
    field: {
      direction: {
        label: 'Richting',
        value: {
          a_to_b: 'A naar B',
          b_to_a: 'B naar A',
        },
      },
      speed: { label: 'Snelheid' },
      tIn: { label: 'T in' },
      deltaT: { label: 'ΔT' },
      deltaX: { label: 'ΔX' },
      co2In: { label: 'CO² in' },
      startTime: { label: 'Tijd' },
    },
  },
}
