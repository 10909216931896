import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import { observable, action } from 'mobx'
import { observer } from 'mobx-react'
import { Modal, Form } from 'semantic-ui-react'
import { WorkStation } from 'store/WorkStation'
import { DataSourceStore } from 'store/DataSource'
import { TargetNumberInput, TargetTextInput, TargetSelect, TargetRadioButtons, TargetDatePicker } from 'spider/semantic-ui/Target'
import { TargetColorSelect } from 'spider/semantic-ui/targets'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import styled from 'styled-components'

const SINGLE_USER_ICONS = {
  true: 'user',
  false: 'users',
}

const SINGLE_USER_OPTIONS = [false, true].map((value) => ({
  value,
  icon: SINGLE_USER_ICONS[value],
  text: t(`workStation.field.singleUser.value.${value}`),
  tooltip: t(`workStation.field.singleUser.tooltip.${value}`),
}))

const PRODUCTION_STRATEGY_OPTIONS = ['together', 'seperate_spread', 'seperate_planned'].map((value) => ({
  value,
  text: t(`workStation.field.productionStrategy.value.${value}`),
  tooltip: t(`workStation.field.productionStrategy.tooltip.${value}`),
}))

const MAINTENANCE_STRATEGY_OPTIONS = ['together', 'seperate'].map((value) => ({
  value,
  text: t(`workStation.field.maintenanceStrategy.value.${value}`),
  tooltip: t(`workStation.field.maintenanceStrategy.tooltip.${value}`),
}))

const LocationContainer = styled.div`
  margin: 1.5rem -1.5rem -1.5rem;
  padding: 1rem 1.5rem 1.5rem;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
`

@observer
export default class WorkStationModal extends Component {
  static propTypes = {
    workStation: PropTypes.instanceOf(WorkStation).isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
  }

  @observable dataSourceStore = new DataSourceStore()

  constructor(...args) {
    super(...args)
    this.renderLocation = this.renderLocation.bind(this)
  }

  renderLocation(location) {
    const { workStation, disabled } = this.props

    return (
      <LocationContainer key={location.cid}>
        <TargetRadioButtons
          label={`${t('workStation.field.singleUser.label')} - ${workStation.name} ${location.ordering + 1}`}
          target={location}
          name="singleUser"
          options={SINGLE_USER_OPTIONS}
          disabled={disabled}
        />
      </LocationContainer>
    )
  }

  render() {
    const { workStation, disabled, ...props } = this.props

    return (
      <Modal {...props}>
        <Modal.Header>{disabled ? t('workStation.editModal.viewTitle') : t('workStation.editModal.editTitle')}</Modal.Header>
        <Modal.Content>
          <Form>
            <TargetTextInput
              placeholder={t('workStation.field.name.label')}
              target={workStation}
              name="name"
              readOnly={disabled}
              disabled={disabled}
              autoFocus
            />
            <TargetColorSelect target={workStation} name="color" />
            <TargetNumberInput width={2} allowDecimal target={workStation} name="defaultFte" disabled={disabled} />
            {workStation.locations.length > 1 && (
              <TargetRadioButtons
                target={workStation}
                name="productionStrategy"
                options={PRODUCTION_STRATEGY_OPTIONS}
                onChange={action((productionStrategy) => {
                  if (productionStrategy === 'together' && workStation.maintenanceStrategy !== 'together') {
                    workStation.setInput('maintenanceStrategy', 'together')
                  }
                  workStation.setInput('productionStrategy', productionStrategy)
                })}
                disabled={disabled}
              />
            )}
            {workStation.productionStrategy === 'together' && (
              <TargetRadioButtons
                target={workStation}
                name="singleUser"
                options={SINGLE_USER_OPTIONS}
                disabled={disabled}
              />
            )}
            {workStation.productionStrategy !== 'together' && (
              <React.Fragment>
                <TargetRadioButtons
                  target={workStation}
                  name="maintenanceStrategy"
                  options={MAINTENANCE_STRATEGY_OPTIONS}
                  disabled={disabled}
                />
                {workStation.locations.map(this.renderLocation)}
              </React.Fragment>
            )}
            <TargetSelect
              remote
              multiple
              store={this.dataSourceStore}
              target={workStation}
              name="dataSources"
              disabled={disabled}
              toOption={(dataSource) => ({
                value: dataSource.id,
                text: dataSource.name,
              })}
            />
            {isFeatureFlagEnabled('blue_skies') && (
              <TargetDatePicker
                target={workStation}
                name="automationDate"
              />
            )}
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}
