import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ProductionLine } from './ProductionLine'
import { WorkStationStore } from './WorkStation'
import { ProcessVersionStore } from './ProcessVersion'
import { WorkScheduleStore } from './WorkSchedule'

export class ProductionLineVersion extends Model {
  static backendResourceName = 'production_line_version'

  @observable id = null
  @observable version = 1
  @observable draft = true
  @observable floorPlan = null

  @observable newest = false
  @observable latest = false

  getUrl() {
    return `/assets/production-line/${this.productionLine.id}/version/${this.version}/edit`
  }

  relations() {
    return {
      productionLine: ProductionLine,
      workStations: WorkStationStore,
      processVersions: ProcessVersionStore,
      workSchedules: WorkScheduleStore,
    }
  }

  fileFields() {
    return ['floorPlan']
  }

  finalize() {
    return this.wrapPendingRequestCount(this.api.post(`${this.url}finalize/`))
  }

  nextVersion() {
    return this.wrapPendingRequestCount(this.api.post(`${this.url}next_version/`))
  }

  toBackend(...args) {
    const res = super.toBackend(...args)
    delete res.newest
    delete res.latest
    return res
  }
}

export class ProductionLineVersionStore extends Store {
  Model = ProductionLineVersion
  static backendResourceName = 'production_line_version'
}
