import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ExactItem } from './ExactItem'
import { BatchTypeStore, TYPE_BUY } from './BatchType'
import { Classification } from './Classification'
import { ArticleTypeStorageLocationStore } from './ArticleTypeStorageLocation'
import { ArticleTypeWarehouseStore } from './ArticleTypeWarehouse'
import { BillOfMaterialVersionStore } from './BillOfMaterialVersion'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'


export class ArticleType extends Model {
  static backendResourceName = 'article_type'
  static omitFields = ['totalStock']

  static idColor = 'purple'

  getIdIcon() {
    return isFeatureFlagEnabled('blue_skies') ? 'seedling' : 'cube'
  }

  getIdPrefix() {
    return isFeatureFlagEnabled('blue_skies') ? 'SKU' : ''
  }

  @observable id = null
  @observable name = ''
  @observable isMake = true
  @observable isBuy = false
  @observable isSerial = false
  @observable startDate = null
  @observable endDate = null
  @observable code = ''
  @observable totalStock = 0

  get _labelContent() {
    return this.code
  }

  getUrl() {
    return `/assets/article-type/${this.id}/edit`
  }

  relations() {
    return {
      exactItem: ExactItem,
      batchTypes: BatchTypeStore,
      storageLocations: ArticleTypeStorageLocationStore,
      classification: Classification,
      billOfMaterialVersions: BillOfMaterialVersionStore,
      warehouses: ArticleTypeWarehouseStore,
    }
  }

  @computed
  get batchTypesBuy() {
    return this.batchTypes.filter((bt) => bt.type === TYPE_BUY)
  }

  toBackend(...args) {
    const res = super.toBackend(...args)
    if (this.isNew) {
      delete res.versions
    }
    return res
  }

  casts() {
    return {
      startDate: Casts.date,
      endDate: Casts.date,
    }
  }
}

export class ArticleTypeStore extends Store {
  Model = ArticleType
  static backendResourceName = 'article_type'
}
