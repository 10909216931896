import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { SalesOrder } from './SalesOrder'
import { ArticleType } from './ArticleType'
import { Project } from './Project'
import { ExactSalesOrderLine } from './ExactSalesOrderLine'

export class SalesOrderLine extends Model {
  static backendResourceName = 'sales_order_line'
  static omitFields = ['quantityDeliveredErp', 'quantityReturnedErp']

  @observable id = null
  @observable quantity = 0
  @observable unitPrice = 0
  @observable netPrice = 0
  @observable productionDueDate = null
  @observable shippable = true
  @observable deliveryDate = null
  @observable itemCode = null

  @observable quantityDeliveredErp = 0
  @observable quantityReturnedErp = 0

  relations() {
    return {
      salesOrder: SalesOrder,
      articleType: ArticleType,
      project: Project,
      exactSalesOrderLine: ExactSalesOrderLine,
    }
  }

  casts() {
    return {
      productionDueDate: Casts.date,
      deliveryDate: Casts.date,
    }
  }

  /**
   * Whether there is at least one BatchType 'sell' for this line's article,
   * which is necessary for creating OutShipment orders.
   */
  @computed get hasSellProcess() {
    return this.articleType.batchTypes.filter(({ type }) => type === 'sell').length > 0
  }

}

export class SalesOrderLineStore extends Store {
  static backendResourceName = 'sales_order_line'
  Model = SalesOrderLine
}
