import { SERVER_DATE_FORMAT } from 'helpers'
import { format } from 'helpers/date'
import { computed, observable } from 'mobx'
import moment from 'moment'
import { Casts, Model, Store } from 'store/Base'
import { BusinessRelation } from './BusinessRelation'
import { ExactPurchaseOrder } from './Exact/PurchaseOrder'
import { InShipmentStore } from './InShipment'
import { PurchaseOrderLineStore } from './PurchaseOrderLine'
import { Warehouse } from 'store/Warehouse'

export const STATUS_OPEN = 'open'
export const STATUS_PARTIAL = 'partial'
export const STATUS_COMPLETE = 'complete'
export const STATUS_CANCELED = 'canceled'
export const STATUSES = [STATUS_OPEN, STATUS_PARTIAL, STATUS_COMPLETE, STATUS_CANCELED]

export class PurchaseOrder extends Model {
  static backendResourceName = 'purchase_order'
  static idPrefix = 'PU'
  static idColor = 'violet'
  static idIcon = 'shopping cart'

  getUrl() {
    return `/warehouse/inbound/order/${this.id}?.id=${this.id}`;
  }

  @observable id = null
  @observable status = STATUS_OPEN
  @observable externalSyncDatetime = null
  @observable externalSyncFailureReason = null
  @observable reference = ''
  @observable orderNumberErp = ''
  @observable orderDate = null
  @observable receiptDate = null
  @observable deleted = false

  relations() {
    return {
      supplier: BusinessRelation,
      lines: PurchaseOrderLineStore,
      inShipments: InShipmentStore,
      exactPurchaseOrder: ExactPurchaseOrder,
      warehouse: Warehouse,
    }
  }

  casts() {
    return {
      externalSyncDatetime: Casts.datetime,
      orderDate: Casts.date,
      receiptDate: Casts.date,
    }
  }

  @computed
  get quantity() {
    let quantity = 0

    if (this.lines) {
      quantity = this.lines.models.reduce((res, l) => res + l.quantity, quantity)
    }

    return quantity
  }

  @computed
  get _status() {
    return t(`purchaseOrder.field.status.value.${this.status}`)
  }

  @computed
  get planningStatus() {
    const today = moment().format(SERVER_DATE_FORMAT)

    const states = []

    // red: Overdue Receipts. Receipt date overdue, no shipment paired.
    if (format(this.receiptDate, SERVER_DATE_FORMAT) < today && this.inShipments.length === 0) {
      states.push('overdue_receipts')
    }

    // orange: Overdue Requests. Shipements overdue (order with shipment paired that is overdue).
    if (format(this.receiptDate, SERVER_DATE_FORMAT) < today && this.inShipments.length > 0) {
      states.push('overdue_requests')
    }

    // green: Receipts This week. Shipments coming this week.
    if (moment(this.receiptDate).isSame(new Date(), 'week')) {
      states.push('receipts_this_week')
    }

    // gray: Future Receipts. Shipments upcoming after this week.
    if (moment(this.receiptDate).isAfter(new Date(), 'week')) {
      states.push('future_receipts')
    }

    return states

    // let status = 'normal';

    // if (
    //     format(this.receiptDate, SERVER_DATE_FORMAT) < today &&
    //     [STATUS_OPEN, STATUS_PARTIAL].includes(this.status)
    // ) {
    //     if (this.inShipments && this.inShipments.length === 0) {
    //         status = 'danger';
    //     } else {
    //         status = 'warning';
    //     }
    // }

    // return status;
  }
}

export class PurchaseOrderStore extends Store {
  static backendResourceName = 'purchase_order'
  Model = PurchaseOrder

  @observable _statusTotals = null
  @observable _requestStatusTotals = null
  @observable _receiptStatusTotals = null

  fetchStatusTotals({ mapParams = (params) => params } = {}) {
    return this.api.get(this.url() + 'status_totals/', mapParams(this.params)).then((res) => {
      this._requestStatusTotals = res.data.request_status
      this._receiptStatusTotals = res.data.receipt_status
      this._statusTotals = res.data.status
    })
  }
}
