import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Toolbar as BaseToolbar } from 're-cy-cle'
import styled from 'styled-components'

const StyledToolbar = styled(BaseToolbar)`
  position: relative;
  padding-left: 70px;
  background-color: ${({ theme }) => theme.toolbarColor};
`

const CyLink = styled.a`
  position: absolute;
  left: 5px;
  top: -15px;
  z-index: 1001;

  transition: filter 600ms ease;

  #cy-logo-bg {
    fill: ${({ theme }) => theme.toolbarColor};
    transition: fill 600ms ease;
  }
  #cy-logo-c {
    fill: rgba(0, 0, 0, 0.2);
    transition: fill 600ms ease;
  }
  #cy-logo-y {
    fill: rgba(0, 0, 0, 0.2);
    transition: fill 600ms ease;
  }

  &:hover {
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));

    #cy-logo-bg {
      fill: #fff;
    }
    #cy-logo-c {
      fill: #ebbb12;
    }
    #cy-logo-y {
      fill: #2d303b;
    }
  }
`

export default class Toolbar extends Component {
  static propTypes = {
    children: PropTypes.node,
  }

  static defaultProps = {
    children: null,
  }

  render() {
    const { children, ...props } = this.props
    return (
      <StyledToolbar {...props}>
        <CyLink href="https://www.codeyellow.nl/" target="_blank" onClick={(e) => e.stopPropagation()}>
          <svg width="60" height="60" viewBox="0 0 1 1">
            <g transform="scale(0.8) translate(0.1, 0.1)">
              <path
                id="cy-logo-bg"
                d="
                                M 0.40625,-0.01875
                                a 0.13257,0.13527 1 0 1 0.1875,0
                                l 0.4375,0.4375
                                a 0.13257,0.13527 1 0 1 0,0.1875
                                l -0.4375,0.4375
                                a 0.13257,0.13527 1 0 1 -0.1875,0
                                l -0.4375,-0.4375
                                a 0.13257,0.13527 1 0 1 0,-0.1875
                                Z
                            "
              />
              <path
                id="cy-logo-c"
                d="
                                M 0.25,0.25 l -0.21875,0.21875 a 0.04419,0.04419 1 0 0 0,0.0625
                                l 0.28125,0.28125
                                l 0.125,-0.125
                                l -0.15625,-0.15625 a 0.04419,0.04419 1 0 1 0,-0.0625
                                l 0.1875,-0.1875 a 0.04419,0.04419 1 0 1 0.0625,0
                                l 0.15625,0.15625
                                l 0.125,-0.125
                                l -0.28125,-0.28125 a 0.04419,0.04419 1 0 0 -0.0625,0 Z
                            "
              />
              <path
                id="cy-logo-y"
                d="
                                M 0.46875,0.96875 a 0.04419,0.04419 1 0 0 0.0625,0
                                l 0.4375,-0.4375 a 0.04419,0.04419 1 0 0 0,-0.0625
                                l -0.09375,-0.09375
                                l -0.15625,0.15625 a 0.04419,0.04419 1 0 1 -0.0625,0
                                l -0.15625,-0.1562
                                l -0.125,0.125
                                l 0.15625,0.15625 a 0.04419,0.04419 1 0 1 0,0.0625
                                l -0.15625,0.15625
                            "
              />
            </g>
          </svg>
        </CyLink>
        {children}
      </StyledToolbar>
    )
  }
}
