import i18next from 'i18next'
import en from 'i18n/translation/en'
import nl from 'i18n/translation/nl'
import de from 'i18n/translation/de'

i18next.init({
  lng: 'en',
  resources: {
    en: { tracy: en },
    nl: { tracy: nl },
    de: { tracy: de },
  },
  defaultNS: 'tracy',
  fallbackNS: 'spider',
  interpolation: {
    escapeValue: false, // not needed for react as it does escape per default to prevent xss!
  },
})

export default i18next
export function t(...params) {
  return i18next.t(...params)
}
