import React from 'react'
import { action, observable, computed, toJS, remove } from 'mobx'
import { Model as BModel, Store as BStore, BinderApi, Casts as BCasts } from 'mobx-spine'
import { range, uniq, mapKeys, mapValues, get, isEmpty } from 'lodash'
import moment from 'moment'
import { snakeToCamel, camelToSnake, FRONTEND_API_BASE_URL } from 'helpers'
import concatWithoutOverlap from 'helpers/concatWithoutOverlap'
import { Popup, Label, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { LocalTime } from 'js-joda'

const StyledIcon = styled(Icon)`
  vertical-align: bottom;
`

const StyledLabel = styled(Label)`
  cursor: pointer;
  white-space: nowrap;
`

function escapeKey(key) {
  return key.toString().replace(/([.\\])/g, '\\$1')
}

function extractFiles(data, prefix = '') {
  let keys = []
  if (Array.isArray(data)) {
    keys = range(data.length)
  } else if (typeof data === 'object' && data !== null) {
    keys = Object.keys(data)
  }

  let files = {}
  for (const key of keys) {
    if (data[key] instanceof Blob) {
      files[prefix + escapeKey(key)] = data[key]
      data[key] = null
    } else if (typeof data[key] === 'object' && data[key] !== null) {
      Object.assign(files, extractFiles(data[key], prefix + escapeKey(key) + '.'))
    }
  }
  return files
}

class MyApi extends BinderApi {
  baseUrl = FRONTEND_API_BASE_URL

  __request(method, url, data, options) {
    if (method !== 'get' && data && !(data instanceof Blob) && !(data instanceof FormData)) {
      const files = extractFiles(data)
      if (Object.keys(files).length > 0) {
        const formData = new FormData()
        formData.append('data', JSON.stringify(data))
        for (const [path, file] of Object.entries(files)) {
          formData.append('file:' + path, file, file.name)
        }
        data = formData
      }
    }
    return super.__request(method, url, data, options)
  }

  fetchStore({ url, data, requestOptions }) {
    return this.get(url, data, requestOptions).then((res) => {
      return {
        response: res,
        data: res.data,
        repos: res.with,
        relMapping: res.with_mapping,
        reverseRelMapping: res.with_related_name_mapping,
        totalRecords: res.meta.total_records,
        meta: res.meta,
      }
    })
  }
}

export const myApi = new MyApi()

// Stolen from mobx spine, since it's not exported.
export function parseBackendValidationErrors(response) {
  const valErrors = get(response, 'data.errors')
  if (response.status === 400 && valErrors) {
    return valErrors
  }
  return null
}

export class Model extends BModel {
  api = myApi
  static idPrefix = ''
  static idColor = ''
  static idIcon = ''
  static ignoreErrors = []

  getUrl() {
    return ''
  }

  @observable __actuallyUsefulErrors = {}

  @computed get _id() {
    let content = this._labelContent ? this._labelContent : this.id
    return this.getIdPrefix() + (content ? content : ' New')
  }

  allUserChanges() {
    let changes = [...this.__changes]

    changes = [
      ...changes,
      ...this.__activeCurrentRelations
        .filter((r) => this[r].hasUserChanges)
        .map((r) => {
          return {
            relation: r,
            changes: this[r].allUserChanges(),
          }
        }),
    ]

    return changes
  }

  getLabel({ hover, ...props } = {}) {
    const idIcon = this.getIdIcon(props)

    let label = (
      <StyledLabel
        color={this.constructor.idColor}
        {...{
          [`data-test-tag-${this.constructor.backendResourceName.replace(/_/g, '-')}`]: this.id,
          ...props,
        }}
      >
        {idIcon && <StyledIcon name={idIcon} />}
        {this._id}
      </StyledLabel>
    )
    if (hover !== undefined) {
      label = <Popup trigger={label} content={hover} />
    }
    return label
  }

  getIdPrefix() {
    return this.constructor.idPrefix
  }

  getIdIcon(props) {
    return this.constructor.idIcon
  }

  getLink(props = {}) {
    const to = this.getUrl()

    if (to !== '') {
      props = {
        as: Link,
        to,
        style: { textDecoration: 'none' },
        ...props,
      }
    }
    return this.getLabel(props)
  }

  @computed get actuallyUsefulErrors() {
    return this.__actuallyUsefulErrors
  }

  markChanged(field) {
    if (!this.__changes.includes(field)) {
      this.__changes.push(field)
    }
  }

  getAllFlattenedWildcardErrors() {
    let errors = this.getWildcardErrors()

    this.__activeCurrentRelations.forEach((attr) => {
      errors = errors.concat(this[attr].getWildcardErrors())
    })
    return uniq(errors)
  }

  getWildcardErrors() {
    return toJS(this.backendValidationErrors['*']) || []
  }

  // Overwrite to for better backend validation.
  @action
  parseValidationErrors(valErrors) {
    const bname = this.constructor.backendResourceName

    if (valErrors[bname]) {
      const id = this.getInternalId()
      // When there is no id or negative id, the backend may use the string 'null'. Bit weird, but eh.
      const errorsForModel = valErrors[bname][id] || valErrors[bname]['null']
      if (errorsForModel) {
        const camelCasedErrors = mapKeys(errorsForModel, (value, key) => snakeToCamel(key))
        const formattedErrors = mapValues(camelCasedErrors, (valError) => {
          return valError.map((obj) => obj.message || obj.code) // Show backend message if available. T16564
        })
        this.__backendValidationErrors = formattedErrors
        this.__actuallyUsefulErrors = camelCasedErrors
      }
    }

    this.__activeCurrentRelations.forEach((currentRel) => {
      this[currentRel].parseValidationErrors(valErrors)
    })
  }

  restore() {
    return this.api.post(this.url)
  }

  @computed get hasErrors() {
    return (
      !isEmpty(this.backendValidationErrors) ||
      this.__activeCurrentRelations
        .filter((rel) => !this.constructor.ignoreErrors.includes(rel))
        .some((rel) => this[rel].hasErrors)
    )
  }

  setInputFromJS(name, value) {
    if (value !== null) {
      const relation = this.relations()[name]
      const prefix = name + '.'
      const relations = (
        this.__activeRelations
        .filter((rel) => rel.startsWith(prefix))
        .map((rel) => rel.slice(prefix.length))
      )
      if (relation.prototype instanceof Model) {
        value = new relation(value, { relations })
      } else if (relation.prototype instanceof Store) {
        value = new relation({ relations }).parse(value)
      }
    }
    return this.setInput(name, value)
  }

  @action setInput(name, value) {
    // TODO: remove once fixed in mobx-spine. This is for the weird code
    // that extends an object instead of simply removing the key from the
    // backendValidationErrors.
    if (this.backendValidationErrors[name]) {
      remove(this.backendValidationErrors, name)
    }

    const res = super.setInput(name, value)
    if (this.actuallyUsefulErrors[name]) {
      this.__actuallyUsefulErrors = { ...this.actuallyUsefulErrors }
      delete this.__actuallyUsefulErrors[name]
    }
    return res
  }

  toBackend(...args) {
    const data = super.toBackend(...args)
    for (const fileField of this.fileFields()) {
      delete data[camelToSnake(fileField)]
    }
    return data
  }
}

export class Store extends BStore {
  api = myApi

  @observable meta = {}

  markChanged() {
    this.__setChanged = true
  }
  getByCid(cid) {
    return this.models.find((model) => model.cid === cid)
  }

  allUserChanges() {
    return this.models.filter((m) => m.hasUserChanges).map((r) => r.allUserChanges())
  }

  getWildcardErrors() {
    let errors = []
    this.models.forEach((model) => {
      errors = errors.concat(model.getWildcardErrors())
    })
    return errors
  }

  ignoreSetChanges(func) {
    const hasSetChanges = this.hasSetChanges
    const res = func()

    if (res instanceof Promise) {
      res
        .catch(() => {})
        .then(() => {
          if (!hasSetChanges) {
            this.clearSetChanges()
          }
        })

      return res
    } else if (!hasSetChanges) {
      this.clearSetChanges()
    }

    return res
  }

  fromBackend({ meta = {}, ...rest }) {
    this.meta = meta
    return super.fromBackend(rest)
  }

  @computed get hasErrors() {
    return this.models.some((model) => model.hasErrors)
  }
}

export const api = myApi

export const Casts = {
  ...BCasts,
  decimal: {
    parse(attr, value) {
      if (value === null) {
        return null
      }
      return value.replace(/,/g, '').replace('.', ',')
    },
    toJS(attr, value) {
      if (value === null || value === '') {
        return null
      }
      return value.replace(/\./g, '').replace(',', '.')
    },
  },
  durationMinutes: {
    parse(attr, value) {
      if (value === null) {
        return null
      }
      return moment.duration(value, 'minutes')
    },
    toJS(attr, value) {
      if (value === null) {
        return null
      }
      // https://github.com/CodeYellowBV/mobx-spine/issues/57
      if (value === 0) {
        return 0
      }
      return value.asMinutes()
    },
  },
  duration: {
    parse(attr, value) {
      if (value === null) {
        return null
      }

      return moment.duration(value)
    },
    toJS(attr, value) {
      if (value === null) {
        return null
      }

      return value.toISOString()
    },
  },
  nullableDatetime: {
    parse(attr, value) {
      return Casts.datetime.parse(attr, value)
    },
    toJS(attr, value) {
      if (value === null) {
        return null
      }

      return Casts.datetime.toJS(attr, value)
    },
  },
  file: {
    parse(attr, value) {
      if (value) {
        return concatWithoutOverlap(myApi.baseUrl, value)
      }

      return null
    },
    toJS(attr, value) {
      return value
    },
  },
  time: {
    parse(attr, value) {
      if (value) {
        return LocalTime.parse(value)
      }

      return null
    },
    toJS(attr, value) {
      if (value) {
        return value.toString()
      }

      return value
    },
  },
  momentTime: {
    parse(attr, value) {
      if (value === null || value === undefined) {
        return null
      }
      return moment(value, 'HH:mm:ss.SSSZZ')
    },
    toJS(attr, value) {
      if (value === null || value === undefined) {
        return null
      }
      return value.format('HH:mm:ss.SSSZZ')
    },
  },
  tzDatetime: {
    parse(attr, value) {
      if (value === null || value === undefined) {
        return null
      }
      return moment.parseZone(value)
    },
    toJS(attr, value) {
      if (value === null) {
        return null
      }
      return value.toISOString(true)
    },
  },
  naiveDatetime: {
    parse(attr, value) {
      if (value === null || value === undefined) {
        return null
      }
      return moment(value, 'YYYY-MM-DDTHH:mm:ss')
    },
    toJS(attr, value) {
      if (value === null) {
        return null
      }
      return value.format('YYYY-MM-DDTHH:mm:ss')
    },
  },
}

export function OrderedStore(UnorderedStore, orderingField) {
  return class extends UnorderedStore {
    comparator = orderingField

    addPos(pos, ...args) {
      this.forEach((obj, i) => {
        if (i >= pos) {
          obj.setInput(orderingField, i + 1)
        }
      })

      const model = this.add(...args)
      model[orderingField] = pos
      this.sort()

      return model
    }

    addFirst(...args) {
      return this.addPos(0, ...args)
    }

    _newModel(model = null, i) {
      const m = super._newModel(model)

      // if (!(orderingField in model)) {
      if (this.length > 0) {
        const nextOrdering = Math.max(...this.map((aModel) => aModel[orderingField])) + 1
        m[orderingField] = isFinite(nextOrdering) ? nextOrdering : this.length + 1
      } else {
        m[orderingField] = i
      }
      // }

      return m
    }
  }
}

export function subscribe(room, callback) {
  const result = api.socket.subscribe({
    onPublish: callback,
    room,
  })

  result.unsubscribe = function () {
    // Fix a rare bug where cypress switches to another view before we are
    // subscribed to this view which sometimes causes cypress to crash
    if (result !== undefined && api.socket !== null) {
      api.socket.unsubscribe(result)
    }
  }

  return result
}
