import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ExactGoodsDeliveryLineStore } from 'store/ExactGoodsDeliveryLine'
import { ExactSalesOrder } from 'store/Exact/SalesOrder'
import { SalesOrderLine } from 'store/SalesOrderLine'
import { ExactItem } from 'store/ExactItem'

export class ExactSalesOrderLine extends Model {
  static backendResourceName = 'exact_sales_order_line'

  @observable id = null
  @observable uuid = null
  @observable number = 0
  @observable quantity = 0
  @observable unit = ''
  @observable deliveryStatus = 0
  @observable unitPrice = 0
  @observable netPrice = 0
  @observable deliverAt = null
  @observable created = null
  @observable modified = null

  relations() {
    return {
      goodsDeliveryLines: ExactGoodsDeliveryLineStore,
      exactSalesOrder: ExactSalesOrder,
      salesOrderLine: SalesOrderLine,
      exactItem: ExactItem,
    }
  }

  casts() {
    return {
      deliverAt: Casts.date,
      created: Casts.datetime,
      modified: Casts.datetime,
    }
  }
}

export class ExactSalesOrderLineStore extends Store {
  Model = ExactSalesOrderLine
  static backendResourceName = 'exact_sales_order_line'
}
