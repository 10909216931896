import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { PurchaseOrder } from './PurchaseOrder'
import { ArticleType } from './ArticleType'
import { BusinessRelation } from './BusinessRelation'
import { InShipmentLineStore } from './InShipmentLine'
import { Project } from './Project'
import { ExactPurchaseOrderLine } from './ExactPurchaseOrderLine'

export class PurchaseOrderLine extends Model {
  static backendResourceName = 'purchase_order_line'
  static omitFields = ['quantityReceivedErp']

  @observable id = null
  @observable quantity = 0
  @observable quantityInPurchaseUnits = 0
  @observable _toReceive = 0 // Small hack to let user decide how many to receive.
  @observable unitPrice = 0
  @observable netPrice = 0
  @observable receiptDate = null
  @observable itemCode = null
  @observable quantityReceivedErp = 0
  @observable autoCreatedByVariableBatches = false

  relations() {
    return {
      purchaseOrder: PurchaseOrder,
      articleType: ArticleType,
      businessRelation: BusinessRelation,
      inShipmentLines: InShipmentLineStore,
      project: Project,
      exactPurchaseOrderLine: ExactPurchaseOrderLine,
    }
  }

  casts() {
    return {
      receiptDate: Casts.date,
    }
  }

  @computed get purchaseUnit() {
    if (this.quantityInPurchaseUnits === 0) {
      return 0
    }
    return this.quantity / this.quantityInPurchaseUnits
  }

  /**
   * Whether there is at least one BatchType 'buy' for this line's article,
   * which is necessary for creating InShipment orders.
   */
  @computed get hasBuyProcess() {
    return this.articleType.batchTypes.filter(({ type }) => type === 'buy').length > 0
  }

}

export class PurchaseOrderLineStore extends Store {
  static backendResourceName = 'purchase_order_line'
  Model = PurchaseOrderLine
}
