import { observable } from 'mobx'
import { Model, Store } from 'store/Base'

export class BusinessRelation extends Model {
  static backendResourceName = 'business_relation'

  @observable id = null
  @observable name = ''
  @observable searchCode = ''
  @observable isSupplier = false
  @observable isCustomer = false
}

export class BusinessRelationStore extends Store {
  static backendResourceName = 'business_relation'
  Model = BusinessRelation
}
