// Hacky helper to ease working with notifications.
let viewStore = null

export function configureNotification(givenViewStore) {
  viewStore = givenViewStore
}

export function showSaveNotification() {
  viewStore.showSaveNotification()
}

export function showDeleteNotification() {
  viewStore.showDeleteNotification()
}

export function showNotification(message) {
  if (typeof message === 'string') {
    viewStore.showNotification({ message })
  } else {
    viewStore.showNotification(message)
  }
}
