import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ProductionRequest } from './ProductionRequest'
import { StorageLocation } from './StorageLocation'
import { Warehouse } from './Warehouse'

export class StockCount extends Model {
  static backendResourceName = 'stock_count'
  static idPrefix = 'SC'
  static idColor = 'purple'

  @observable id = null

  relations() {
    return {
      productionRequest: ProductionRequest,
      warehouse: Warehouse,
      // storage location is default
      // because when performing the actual batch step
      // the operator can correct the location if the
      // product was moved
      defaultStorageLocation: StorageLocation,
    }
  }
}

export class StockCountStore extends Store {
  Model = StockCount
  static backendResourceName = 'stock_count'
}
