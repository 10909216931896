import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ProductionRequest } from './ProductionRequest'
import { BillOfMaterialVersion } from './BillOfMaterialVersion'
import { Warehouse } from './Warehouse'

export class ProductionOrder extends Model {
  static backendResourceName = 'production_order'
  static idPrefix = 'PR'
  static idColor = 'orange'
  static idIcon = 'handyman'

  @observable id = null
  @observable status = 'open'

  relations() {
    return {
      productionRequest: ProductionRequest,
      billOfMaterialVersion: BillOfMaterialVersion,
      warehouse: Warehouse,
    }
  }

  async release() {
    try {
      const res = await this.wrapPendingRequestCount(this.api.post(`${this.url}release/`))
      this.status = 'released'
      return res
    } catch (err) {
      if (err.response) {
        const valErrors = this.api.parseBackendValidationErrors(err.response)
        if (valErrors) {
          this.parseValidationErrors(valErrors)
        }
      }
      throw err
    }
  }
}

export class ProductionrderStore extends Store {
  static backendResourceName = 'production_order'

  Model = ProductionOrder
}
