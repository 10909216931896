import { tint } from 'polished'

const primaryColor = '#41BCBE'
const secondaryColor = '#1e2033'
const accentColor = '#ff9040';
const menuColor = '#1e2033'
const tintedPrimaryColor = tint(0.15, primaryColor)
const toolbarColor = tint(0.15, menuColor)
const buttonColor = '#008080'
const dangerColor = '#dc0818'
const warningColor = '#ffc107'

export const theme = {
  primaryColor,
  secondaryColor,
  menuColor,
  tintedPrimaryColor,
  toolbarColor,
  buttonColor,
  dangerColor,
  warningColor,
  accentColor,
}
