import qz from 'qz-tray'
import sha from 'sha.js'

import { api } from 'store/Base'

let __onPrintError = () => {}
let mockPrinter = false

export function setMockPrinter(value) {
  mockPrinter = value
}

export function onPrintError(err) {
  return __onPrintError(err)
}

export function setOnPrintError(func) {
  __onPrintError = func
}

qz.api.setPromiseType((resolver) => new Promise(resolver))
qz.api.setSha256Type((data) => sha('sha256').update(data).digest('hex'))

qz.security.setCertificatePromise((resolve, reject) => api.get('certificate/').then(resolve).catch(reject))

qz.security.setSignaturePromise((message) => (resolve, reject) =>
  api.get('sign/', { message }).then(resolve).catch(reject)
)

let qzConnection = null

qz.websocket.setClosedCallbacks([() => qzConnection = null])

export async function connect() {
  if (qzConnection === null) {
    qzConnection = qz.websocket.connect()
  }
  try {
    return await qzConnection
  } catch (e) {
    qzConnection = null
    return e
  }
}

async function testConnection(printer) {
  if (mockPrinter) {
    window.viewStore.showNotification({
      key: 'mockPrinter',
      error: false,
      dismissAfter: 4000,
      message: 'Printer Mocked not connecting',
      icon: 'print',
    })
    return;
  }

  await connect()
  await qz.printers.find(printer)
}

export async function printPdf(printer, file, { copies = 1, handleError = true, key = '' }) {
  if (mockPrinter) {
    window.viewStore.showNotification({
      key: 'printInstructions' + key,
      error: false,
      dismissAfter: 4000,
      message: `Print Document: ${file}`,
      icon: 'print',
    })
    return
  }

  const pdfData = Buffer.from(await api.get(file.slice('/api'.length), undefined, { responseType: 'arraybuffer' })).toString('base64')

  const data = []
  for (let i = 0; i < copies; i++) {
    data.push({
      type: 'raw',
      format: 'pdf',
      flavor: 'base64',
      data: pdfData,
    })
  }

  try {
    await connect()
    return await qz.print(qz.configs.create(printer), data)
  } catch (err) {
    if (handleError) {
      onPrintError(err)
    }
  }
}

export async function getPrinters() {
  if (mockPrinter) {
    return ['altec', 'zebra', 'document']
  }
  await connect()
  return await qz.printers.find()
}


export async function print(printer, instructions, { copies = 1, handleError = true, key = '' } = {}) {
  instructions = instructions.repeat(copies)

  if (mockPrinter) {
    window.viewStore.showNotification({
      key: 'printInstructions' + key,
      error: false,
      dismissAfter: 4000,
      message: `Print Instructions (${printer}): ${instructions}`,
      icon: 'print',
    })
    return
  }

  // use this for local testing to connect directly to printer via printer IP in CY office
  // if (qzConnection === null) {
  //     const options = {'host': ["10.97.14.144"]};
  //     qzConnection = qz.websocket.connect(options).catch((err) => {
  //         throw err;
  //     });
  // }

  try {
    await connect()
    printer = await qz.printers.find(printer)
    return await qz.print(qz.configs.create(printer), [instructions])
  } catch (err) {
    if (handleError) {
      onPrintError(err)
    }
    throw err
  }
}

const PRINTERS = [
  {
    type: 'altec',
    print: (instructions, ...args) => print('altec', instructions + '\n', ...args),
    testConnection: (...args) => testConnection('altec', ...args),
  },
  {
    type: 'zebra',
    print: (...args) => print('zebra', ...args),
    testConnection: (...args) => testConnection('zebra', ...args),
  },
]

const PRINT_CODE_TEMPLATES = {
  altec: (code, name) =>
    'CLS\n' +
    'SIZE 60 mm, 40 mm\n' +
    'GAP 10 mm\n' +
    'OFFSET 0 mm\n' +
    '\n' +
    'DIRECTION 1,0\n' +
    'REFERENCE 0,0\n' +
    'DENSITY 8\n' +
    'SPEED 2.0\n' +
    '\n' +
    'SET RIBBON ON\n' +
    '\n' +
    'SET PEEL OFF\n' +
    'SET CUTTER OFF\n' +
    '\n' +
    'SET TEAR ON\n' +
    '\n' +
    `BARCODE 360,122,"128",200,0,0,4,4,2,"${code}"\n` +
    `TEXT ${360 - 10 * name.length},334,"1",0,2,2,"${name}"\n` +
    '\n' +
    'PRINT 1\n',
}

export const PRINTERS_WITH_CODE_TEMPLATE = PRINTERS.map((p) => p.type).filter(
  (type) => PRINT_CODE_TEMPLATES[type] !== undefined
)

export function printCode(code, name, printers = PRINTERS_WITH_CODE_TEMPLATE) {
  if (printers.length === 0) {
    return onPrintError(t('productionLine.codesModal.noPrintersAvailable'))
  }

  const head = printers[0]
  const tail = printers.slice(1)
  const { print } = PRINTERS.find((p) => p.type === head)

  return print(PRINT_CODE_TEMPLATES[head](code, name), false).catch(() => printCode(code, name, tail))
}

export default PRINTERS
