import { observable, action } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleType } from './ArticleType'
import { ProcessVersionStore } from './ProcessVersion'
import { regexEscape } from 'helpers'


export const TYPE_BUY = 'buy'
export const TYPE_MAKE = 'make'
export const TYPE_SELL = 'sell'
export const TYPE_TRANSFER = 'transfer'
export const TYPE_TRANSFER_LINE = 'transfer_line'
export const TYPE_ON_THE_FLY = 'on_the_fly'
export const TYPE_RECEIVE = 'receive_order'
export const TYPE_PICK = 'pick_order'
export const TYPE_COMPONENT = 'component'
export const TYPE_SUBASSEMBLY = 'subassembly'
export const TYPE_STOCK_COUNT = 'stock_count'

export const GLOBAL_TYPES = [
  TYPE_RECEIVE,
  TYPE_PICK,
  TYPE_TRANSFER,
]
export const LOCAL_TYPES = [
  TYPE_BUY,
  TYPE_MAKE,
  TYPE_SELL,
  TYPE_TRANSFER_LINE,
  TYPE_ON_THE_FLY,
  TYPE_STOCK_COUNT,
]
export const USER_DEFINED_TYPES = [...GLOBAL_TYPES, ...LOCAL_TYPES]
export const TEMPLATE_TYPES = LOCAL_TYPES.map((type) => `${type}_template`)
export const SYSTEM_TYPES = [TYPE_COMPONENT, TYPE_SUBASSEMBLY]
export const TYPES = [...USER_DEFINED_TYPES, ...TEMPLATE_TYPES, ...SYSTEM_TYPES]

export const TYPE_ICONS = {
  [ TYPE_MAKE ]: 'tools',
  [ TYPE_BUY ]: 'shopping basket',
  [ TYPE_RECEIVE ]: 'shopping basket',
  [ TYPE_SELL ]: 'shipping fast',
  [ TYPE_PICK ]: 'shipping fast',
  [ TYPE_TRANSFER ]: 'dolly',
  [ TYPE_TRANSFER_LINE ]: 'dolly',
  [ TYPE_STOCK_COUNT ]: 'boxes',
}

export const ISSERIAL_ICON = 'barcode'

export class BatchType extends Model {
  static backendResourceName = 'batch_type'
  static omitFields = ['targetCount']
  static ON_THE_FLY_TYPES = ['fixed', 'flexible', 'none']
  static ON_THE_FLY_SOURCES = ['new', 'existing']

  @observable id = null
  @observable quantity = 1
  @observable type = TYPE_MAKE
  @observable serialNumberFormat = [{
    type: 'code',
    alphabet: '0123456789',
    digits: 8,
    expand: true,
  }]
  @observable description = ''
  @observable deleted = false
  @observable default = false
  @observable targetCount = 0
  @observable onTheFlyType = 'fixed'
  @observable onTheFlySize = 1
  @observable onTheFlySource = 'new'

  get serialNumberRegex() {
    return new RegExp(`^${this.serialNumberFormat.map((part) => {
      switch (part.type) {
        case 'text':
          return regexEscape(part.content)
        case 'code':
          return `${part.expand ? `([${regexEscape(part.alphabet.slice(1))}][${regexEscape(part.alphabet)}]*)?` : ''}[${regexEscape(part.alphabet)}]{${part.digits}}`
        case 'date':
          if (part.part === 'isoweekday' && part.format === 'd') {
            return '[1-7]'
          } else if (part.format === 'text') {
            return part.names.map(regexEscape).join('|')
          } else {
            return {
                yyyy: '\\d{4}',
                yy: '\\d{2}',
                mm: '0[1-9]|1[0-2]',
                m: '[1-9]|1[0-2]',
                dd: '0[1-9]|[1-2]\\d|3[0-1]',
                d: '[1-9]|[1-2]\\d|3[0-1]',
                ww: '0[1-9]|[1-4]\\d|5[0-3]',
                w: '[1-9]|[1-4]\\d|5[0-3]',
            }[part.format]
          }
        case 'anything':
          return '.*'
        default:
          throw new Error(`unknown type: ${part.type}`)
      }
    }).join('')}$`)
  }

  toBackend(...args) {
    const data = super.toBackend(...args)
    if (this.type !== 'on_the_fly') {
      data.on_the_fly_type = null
      data.on_the_fly_source = null
    }
    if (this.type !== 'on_the_fly' || this.onTheFlyType !== 'fixed') {
      data.on_the_fly_size = null
    }
    if (this.type !== 'on_the_fly') {
      delete data.serial_number_format
    }
    return data
  }

  @action toBackendAll(...args) {
    let oldRelations = null
    if (
      this.processVersions &&
      ((this.source && !this.source.isNew) || this.type === 'on_the_fly')
    ) {
      oldRelations = this.__activeCurrentRelations
      this.__activeCurrentRelations = oldRelations.filter((rel) => rel !== 'processVersions')
    }

    try {
      return super.toBackendAll(...args)
    } finally {
      if (oldRelations !== null) {
        this.__activeCurrentRelations = oldRelations
      }
    }
  }

  relations() {
    return {
      articleType: ArticleType,
      processVersions: ProcessVersionStore,
      source: BatchType,
      targets: BatchTypeStore,
    }
  }
}

export class BatchTypeStore extends Store {
  Model = BatchType
  static backendResourceName = 'batch_type'
}
