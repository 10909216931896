import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { action, observable } from 'mobx'
import { TopMenu, MenuRow, NavMenu, NavItem, NavItemExternal, ReCyCleTheme } from 're-cy-cle'
import { Button, Modal, Icon, Popup, Label } from 'semantic-ui-react'
import { IconButton } from 'spider/semantic-ui/Button'
import { Route, withRouter } from 'react-router-dom'
import ImgLogo from 'image/logo.svg'
import { SmallAvatar } from 'component/UserAvatar'
import { BUILD_INFO, TIME_FORMAT, PRINT_URL_PARAMS } from 'helpers'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import PRINTERS from 'helpers/print'
import { theme } from 'styles'
import { Link, NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import RightDivider from 'spider/component/RightDivider'
import { User } from 'store/User'
import { Operator } from 'store/Operator'
import { GlobalValue } from 'store/GlobalValue'

// Copy paste from re-cy-cle just so I could remove the padding...
const Logo = styled(({ children, ...props }) => <NavLink to="/" {...props}>{children}</NavLink>)`
  display: flex;
  align-items: center;
  margin: 0 10px;
  font-size: 32px;
  font-weight: 300;
  text-decoration: none;
  color: inherit;

  svg,
  img {
    max-width: 100%;
  }
`

// Copy paste from re-cy-cle so that it could be a div
export const NavDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin: 0 10px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  position: relative;
  &.active {
    &:before,
    &:after {
      border-width: 8px;
    }
  }
  &:after {
    position: absolute;
    left: 50%;
    bottom: -1px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-bottom-color: #fff;
    border-top: 0;
    transition: 175ms all ease;
  }
  &:before {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    content: '';
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-bottom-color: ${(props) => props.theme.primaryColor};
    border-top: 0;
    transition: 175ms all ease;
  }
`

const ITEM_HEIGHT = '2.5em'

const UserDropdown = styled.div`
  position: relative;
  z-index: 200;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  overflow: hidden;
  background-color: #fff;
  border-bottom-left-radius: 0.33em;
  border-bottom-right-radius: 0.33em;
  ${({ open }) =>
    open
      ? css`
          height: ${({ children }) => `calc(50px + ${ITEM_HEIGHT} * ${children.filter((child) => child).length - 1})`};
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
        `
      : css`
          height: 50px;
        `}
  transition: height 300ms ease, box-shadow 300ms ease;
`

const UserDropdownHeader = styled.div`
  flex: 0 0 auto;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  ${({ operator }) => `
        cursor: pointer;
    `};
`

const userDropdownItemCss = css`
  padding: 0 10px;
  flex: 0 0 auto;
  height: ${ITEM_HEIGHT};
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.75);

  position: relative;
  > i.icon,
  > i.icons {
    position: absolute;
    line-height: 1;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const hoverableUserDropdownItemCss = css`
  ${userDropdownItemCss}
  &:hover {
    background-color: #eee;
    color: rgba(0, 0, 0, 0.87);
  }
  transition: color 300ms ease, background-color 300ms ease;
`

const UserDropdownMessage = styled.div`
  font-style: italic;
  background-color: ${theme.tintedPrimaryColor};
  ${userDropdownItemCss}
`

const UserDropdownLink = styled(Link)`
  color: inherit;
  ${hoverableUserDropdownItemCss}
`

const UserDropdownButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  ${hoverableUserDropdownItemCss}
`

const ClockedInIcon = styled(Icon)`
  margin: 0 0 0 0.5rem !important;
  line-height: 1;
  align-self: center;
`

const MyLogo = () => (
  <Logo>
    <img src={ImgLogo} alt="TRACY" height="35" />
  </Logo>
)

@withRouter
@observer
export default class AppHeader extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  @observable debug = false
  @observable printed = false
  @observable loginUrlPrintGlobalValue
  @observable printerModel

  toggleDebug = () => {
    this.debug = !this.debug

    if (this.debug) {
      localStorage.setItem('debug', true)
    } else {
      localStorage.removeItem('debug')
    }
  }

  constructor(...args) {
    super(...args)
    this.debug = !!localStorage.getItem('debug')
    this.onLogout = this.onLogout.bind(this)
    this.onClick = this.onClick.bind(this)
    this.loginUrlPrintGlobalValue = new GlobalValue({ key: 'login_url_print' })
    this.printerModel = new GlobalValue({ key: 'printer_model' })
  }

  @action onClick(e) {
    this.userDropdownClicked = false

    let target = e.target
    while (target) {
      if (target.id === 'user-dropdown-header') {
        this.userDropdownClicked = true
        break
      }
      target = target.parentElement
    }
  }

  componentDidMount() {
    window.addEventListener('click', this.onClick)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClick)
  }

  hasPermission = (perms) => {
    return this.props.store.currentUser.hasPermission(perms)
  }

  renderWorkStationProduction = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.production.productionRequests')}
          to="/production/production-request/overview"
          activePath="/production/production-request/"
        />
      </NavMenu>
    )
  }

  renderProduction = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.production.progress')}
          to="/production/production-request/overview"
          activePath="/production/production-request/"
        />
        <NavItem title={t('nav.production.batches')} to="/production/batch/overview" activePath="/production/batch/" />
        <NavItem
          title={t('nav.production.workStationSessions')}
          to="/production/work-station-session/overview"
          activePath="/production/work-station-session/"
        />
      </NavMenu>
    )
  }

  renderPlanning = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.planning.productionRequests')}
          to="/planning/production-request/overview"
          activePath="/planning/production-request/"
        />
        {isFeatureFlagEnabled('blue_skies') && (
          <NavItem
            title={t('nav.planning.rampUps')}
            to="/planning/sandbox/overview"
            activePath="/planning/sandbox/"
          />
        )}
        {isFeatureFlagEnabled('blue_skies') && (
          <NavItem
            title={t('nav.planning.workStations')}
            to="/planning/work-station/overview"
            activePath="/planning/work-station/"
          />
        )}
        {isFeatureFlagEnabled('blue_skies') && (
          <NavItem
            title={t('nav.planning.day')}
            to="/planning/day/overview"
            activePath="/planning/day/"
          />
        )}
      </NavMenu>
    )
  }

  renderWarehouse = () => {
    return (
      <NavMenu>
        <NavItem title={t('nav.warehouse.stock')} to="/warehouse/stock" />
        <NavItem title={t('nav.warehouse.inbound')} to="/warehouse/inbound" />
        <NavItem title={t('nav.warehouse.outbound')} to="/warehouse/outbound" />
        <NavItem title={t('nav.warehouse.warehouseTransfer')} to="/warehouse/warehouse-transfer" />
      </NavMenu>
    )
  }

  renderPersonel = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.personnel.operators')}
          to="/personnel/operator/overview"
          activePath="/personnel/operator/"
        />
        <NavItem title={t('nav.personnel.users')} to="/personnel/user/overview" activePath="/personnel/user/" />
        <NavItem
          title={t('nav.personnel.leaveSlots')}
          to="/personnel/leave-slot/overview"
          activePath="/personnel/leave-slot/"
        />
        <NavItem
          title={t('nav.personnel.leaveCalendars')}
          to="/personnel/leave-calendar/overview"
          activePath="/personnel/leave-calendar/"
        />
        <NavItem
          title={t('nav.assets.capabilities')}
          to="/personnel/capability/overview"
          activePath="/personnel/capability/"
        />
      </NavMenu>
    )
  }

  renderPlantation = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.plantation.plantationLines')}
          to="/plantation/plantation-line/overview"
          activePath="/plantation/plantation-line/"
        />
        <NavItem
          title={t('nav.plantation.scripts')}
          to="/plantation/script/overview"
          activePath="/plantation/script/"
        />
        <NavItem
          title={t('nav.plantation.units')}
          to="/plantation/unit/overview"
          activePath="/plantation/unit/"
        />
      </NavMenu>
    )
  }

  renderAssets = () => {
    const { store } = this.props

    return (
      <NavMenu>
        <NavItem
          title={t('nav.assets.articleTypes')}
          to="/assets/article-type/overview"
          activePath="/assets/article-type/"
        />
        <NavItem
          title={t('nav.assets.processes')}
          to="/assets/process/overview"
          activePath="/assets/process/"
        />
        <NavItem
          title={t('nav.assets.productionLines')}
          to="/assets/production-line/overview"
          activePath="/assets/production-line/"
        />
        {store.machineEnabled && (
          <NavItem
            title={t('nav.assets.dataSources')}
            to="/assets/data-source/overview"
            activePath="/assets/data-source/"
          />
        )}
        <NavItem
          title={t('nav.assets.loadCarriers')}
          to="/assets/load-carrier/overview"
          activePath="/assets/load-carrier/"
        />
        <NavItem
          title={t('nav.assets.projects')}
          to="/assets/projects/overview"
          activePath="/assets/projects/"
        />
        <NavItem
          title={t('nav.assets.factories')}
          to="/assets/factory/overview"
          activePath="/assets/factory/"
        />
        <NavItem
          title={t('nav.assets.globalValues')}
          to="/assets/global-value/overview"
          activePath="/assets/global-value/"
        />
        <NavItem
          title={t('nav.assets.integrations')}
          to="/assets/integration/overview"
          activePath="/assets/integration/"
        />
      </NavMenu>
    )
  }

  @observable logoutClockOut = null

  async onLogout() {
    const { store, history } = this.props

    const user = !store.currentOperator.isNew ? store.currentOperator : store.currentUser

    if (user.clockInTime) {
      let clockOut
      try {
        clockOut = await new Promise((resolve, reject) => (this.logoutClockOut = { resolve, reject }))
      } finally {
        this.logoutClockOut = null
      }

      if (clockOut) {
        await store.clockOut()
      }
    }

    await store.performLogout()
    history.push('/')
  }

  @observable userDropdownHover = false
  @observable userDropdownClicked = false

  renderAccountMenu = () => {
    const { store } = this.props
    const { version, branch } = BUILD_INFO

    const user = !store.currentOperator.isNew ? store.currentOperator : store.currentUser

    let res = (
      <UserDropdownHeader id="user-dropdown-header" operator={user instanceof Operator}>
        <SmallAvatar user={user} />
        <span data-test-current-user style={{ paddingLeft: '0.5rem' }}>
          {user.fullName} ({branch && branch !== 'production' && branch + ' '}
          {version})
        </span>
        {user instanceof User ? (
          <ClockedInIcon
            data-test-clocked-in={user.clockInTime !== null}
            color={user.clockInTime ? 'green' : 'red'}
            name="clock"
          />
        ) : (
          <ClockedInIcon data-test-logout-icon name="sign-out" />
        )}
      </UserDropdownHeader>
    )

    if (user instanceof User) {
      res = (
        <UserDropdown
          open={this.userDropdownHover || this.userDropdownClicked}
          onMouseEnter={() => (this.userDropdownHover = true)}
          onMouseLeave={() => (this.userDropdownHover = false)}
        >
          {res}
          <UserDropdownMessage data-test-clock-in-time>
            {user.clockInTime
              ? t('nav.account.clockInTime', { clockInTime: user.clockInTime.format(TIME_FORMAT) })
              : t('nav.account.notClockedIn')}
          </UserDropdownMessage>
          {user.clockInTime ? (
            <UserDropdownButton data-test-clock-out-button onClick={store.clockOut}>
              <Icon.Group>
                <Icon name="clock" />
                <Icon corner color="red" name="stop" />
              </Icon.Group>
              {t('nav.account.clockOut')}
            </UserDropdownButton>
          ) : (
            <UserDropdownButton data-test-clock-in-button onClick={store.clockIn}>
              <Icon.Group>
                <Icon name="clock" />
                <Icon corner color="green" name="play" />
              </Icon.Group>
              {t('nav.account.clockIn')}
            </UserDropdownButton>
          )}
          <UserDropdownLink data-test-profile-button to="/profile">
            <Icon name="user" />
            {t('nav.account.profile')}
          </UserDropdownLink>
          <UserDropdownLink data-test-changelog-button to="/changelog">
            <Icon name="list" />
            {t('nav.account.changelog')}
          </UserDropdownLink>
          <UserDropdownButton data-test-logout-button onClick={this.onLogout}>
            <Icon name="sign-out" />
            {t('nav.account.logout')}
          </UserDropdownButton>
        </UserDropdown>
      )
    } else {
      res = (
        <Modal closeIcon size="tiny" trigger={res}>
          <Modal.Header>{t('operator.logoutModal.title')}</Modal.Header>
          <Modal.Content>{t('operator.logoutModal.content')}</Modal.Content>
          <Modal.Actions>
            <Button
              primary
              data-test-logout-button
              icon="sign-out"
              labelPosition="left"
              content={t('operator.logoutModal.logoutButton')}
              onClick={() => this.props.store.performLogout().then(() => this.props.history.push('/operator/login'))}
            />
          </Modal.Actions>
        </Modal>
      )
    }

    return (
      <>
        {res}
        {this.logoutClockOut && (
          <Modal open closeIcon onClose={() => this.logoutClockOut.reject()}>
            <Modal.Header>{t('nav.account.clockOutModal.title')}</Modal.Header>
            <Modal.Content>{t('nav.account.clockOutModal.message')}</Modal.Content>
            <Modal.Actions>
              <RightDivider />
              <Button
                compact
                color="green"
                icon="check"
                labelPosition="left"
                content={t('form.yes')}
                onClick={() => this.logoutClockOut.resolve(true)}
              />
              <Button
                compact
                color="red"
                icon="delete"
                labelPosition="left"
                content={t('form.no')}
                onClick={() => this.logoutClockOut.resolve(false)}
              />
            </Modal.Actions>
          </Modal>
        )}
      </>
    )
  }

  render() {
    const { currentUser } = this.props.store

    if (this.props.store.isWorkStation) {
      return (
        <ReCyCleTheme theme={{ ...theme, primaryColor: theme.menuColor }}>
          <TopMenu>
            <MenuRow>
              <MyLogo />
              <NavMenu></NavMenu>
              <NavDiv>
                <IconButton
                  data-test-print-login-url
                  name="print"
                  onClick={async () => {
                    await Promise.all([
                      this.loginUrlPrintGlobalValue.fetch(),
                      this.printerModel.fetch(),
                    ])
                    let template = this.loginUrlPrintGlobalValue.value

                    const { slug, app_url } = PRINT_URL_PARAMS
                    template = template.replace(/\{\{slug\}\}/g, slug)
                    template = template.replace(/\{\{app_url\}\}/g, app_url)

                    const printer = PRINTERS.find((p) => p.type === this.printerModel.value)
                    printer.print(template).then(
                      action(() => {
                        this.printed = true
                      })
                    )
                  }}
                />
              </NavDiv>
              <Modal
                closeIcon
                size="tiny"
                trigger={
                  <NavDiv>
                    <Icon name="close" size="large" />
                  </NavDiv>
                }
              >
                <Modal.Header>{t('workStation.stopModal.title')}</Modal.Header>
                <Modal.Content>{t('workStation.stopModal.content')}</Modal.Content>
                <Modal.Actions>
                  <Button
                    primary
                    icon="close"
                    labelPosition="left"
                    content={t('workStation.stopModal.stopButton')}
                    onClick={() => this.props.store.performLogout()}
                  />
                </Modal.Actions>
              </Modal>
            </MenuRow>
            <MenuRow>
              {/* Hackity hack hack to always show second menu bar */}
              <NavItem title=" " to="/" activePath="/neverusethispath" />
            </MenuRow>
          </TopMenu>
        </ReCyCleTheme>
      )
    }

    if (this.props.store.isOperator) {
      return (
        <ReCyCleTheme theme={{ ...theme, primaryColor: theme.menuColor }}>
          <TopMenu>
            <MenuRow>
              <MyLogo />
              <NavMenu></NavMenu>
              {this.renderAccountMenu()}
            </MenuRow>
            <MenuRow />
          </TopMenu>
        </ReCyCleTheme>
      )
    }

    if (!this.props.store.isAuthenticated || (currentUser.groupNames === undefined && !currentUser.isSuperuser)) {
      return (
        <ReCyCleTheme theme={{ ...theme, primaryColor: theme.menuColor }}>
          <TopMenu>
            <MenuRow>
              <MyLogo />
            </MenuRow>
            <MenuRow />
          </TopMenu>
        </ReCyCleTheme>
      )
    }

    const logoutModal = (
      <Modal closeIcon open={this.showLogoutModal} onClose={() => (this.showLogoutModal = false)} basic size="small">
        <Modal.Header icon="archive" content={t('user.account.logoutButton')} />
        <Modal.Content>
          <p>{t('user.account.logout.confirm')}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="red" inverted onClick={() => (this.showLogoutModal = false)}>
            <Icon name="remove" /> {t('form.no')}
          </Button>
          <Button
            color="green"
            inverted
            onClick={() => {
              this.props.store.performLogout().then(() => {
                this.showLogoutModal = false
              })
            }}
          >
            <Icon name="checkmark" /> {t('form.yes')}
          </Button>
        </Modal.Actions>
      </Modal>
    )

    let menu = currentUser.isSuperuser ? (
      <TopMenu>
        <MenuRow>
          <MyLogo />
          <NavMenu>
            <NavItem title={t('nav.personnel.users')} to="/personnel/user/overview" activePath="/personnel/user/" />
          </NavMenu>
          {this.renderAccountMenu()}
        </MenuRow>
        <MenuRow>
          <Route path="/account" render={this.renderAccount} />
          {/* Hackity hack hack to always show second menu bar */}
          <NavItem title=" " to="/" activePath="/neverusethispath" />
        </MenuRow>
      </TopMenu>
    ) : (
      <TopMenu>
        <MenuRow>
          <MyLogo />
          <NavMenu>
            <NavItem
              title={t('nav.main.production')}
              to="/production/production-request/overview"
              activePath="/production/"
            />
            <NavItem
              title={t('nav.main.planning')}
              to="/planning/production-request/overview"
              activePath="/planning/"
            />
            <NavItem title={t('nav.main.warehouse')} to="/warehouse/stock" activePath="/warehouse/" />
            <NavItem title={t('nav.main.personnel')} to="/personnel/operator/overview" activePath="/personnel/" />
            {isFeatureFlagEnabled('blue_skies') && <NavItem title={t('nav.main.plantation')} to="/plantation/plantation-line/overview" activePath="/plantation/" />}
            <NavItem title={t('nav.main.assets')} to="/assets/article-type/overview" activePath="/assets/" />
          </NavMenu>
          {this.renderAccountMenu()}
        </MenuRow>
        <MenuRow>
          <Route path="/production/" render={this.renderProduction} />
          <Route path="/planning/" render={this.renderPlanning} />
          <Route path="/warehouse/" render={this.renderWarehouse} />
          <Route path="/personnel/" render={this.renderPersonel} />
          {isFeatureFlagEnabled('blue_skies') && <Route path="/plantation/" render={this.renderPlantation} />}
          <Route path="/assets/" render={this.renderAssets} />
        </MenuRow>
      </TopMenu>
    )

    return (
      <>
        <ReCyCleTheme theme={{ ...theme, primaryColor: theme.menuColor }}>{menu}</ReCyCleTheme>
        {logoutModal}
      </>
    )
  }
}
