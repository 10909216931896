import { SERVER_DATE_FORMAT } from 'helpers'
import { format } from 'helpers/date'
import { computed, observable } from 'mobx'
import moment from 'moment'
import { Casts, Model, Store } from 'store/Base'
import { BusinessRelation } from './BusinessRelation'
import { ExactSalesOrder } from './Exact/SalesOrder'
import { OutShipmentStore } from './OutShipment'
import { SalesOrderLineStore } from './SalesOrderLine'
import { Warehouse } from 'store/Warehouse'

export const STATUS_OPEN = 'open'
export const STATUS_PARTIAL = 'partial'
export const STATUS_COMPLETE = 'complete'
export const STATUS_CANCELED = 'canceled'
export const STATUSES = [STATUS_OPEN, STATUS_PARTIAL, STATUS_COMPLETE, STATUS_CANCELED]

export class SalesOrder extends Model {
  static backendResourceName = 'sales_order'
  static idPrefix = 'SA'
  static idColor = 'green'
  static idIcon = 'local_atm_white'
  static omitFields = ['deliveryStatus']

  getUrl() {
    return `/warehouse/outbound/order/${this.id}?.id=${this.id}`;
  }

  @observable id = null
  @observable status = STATUS_OPEN
  @observable externalSyncDatetime = null
  @observable externalSyncFailureReason = null
  @observable reference = ''
  @observable orderNumberErp = ''
  @observable remarks = ''
  @observable description = ''
  @observable orderDate = null
  @observable deliveryAddress = ''
  @observable deliveryDate = null
  @observable amountNoVat = 0
  @observable deleted = false

  relations() {
    return {
      customer: BusinessRelation,
      lines: SalesOrderLineStore,
      outShipments: OutShipmentStore,
      exactSalesOrder: ExactSalesOrder,
      warehouse: Warehouse,
    }
  }

  casts() {
    return {
      externalSyncDatetime: Casts.datetime,
      orderDate: Casts.date,
      deliveryDate: Casts.date,
    }
  }

  @computed
  get _status() {
    return t(`salesOrder.field.status.value.${this.status}`)
  }

  @computed
  get planningStatus() {
    const today = moment().format(SERVER_DATE_FORMAT)

    const states = []

    // red: Overdue Receipts. Receipt date overdue, no shipment paired.
    if (format(this.deliveryDate, SERVER_DATE_FORMAT) < today && this.outShipments.length === 0) {
      states.push('overdue_receipts')
    }

    // orange: Overdue Requests. Shipements overdue (order with shipment paired that is overdue).
    if (format(this.deliveryDate, SERVER_DATE_FORMAT) < today && this.outShipments.length > 0) {
      states.push('overdue_requests')
    }

    // green: Receipts This week. Shipments coming this week.
    if (moment(this.deliveryDate).isSame(new Date(), 'week')) {
      states.push('receipts_this_week')
    }

    // gray: Future Receipts. Shipments upcoming after this week.
    if (moment(this.deliveryDate).isAfter(new Date(), 'week')) {
      states.push('future_receipts')
    }

    return states

    // const today = moment().format(SERVER_DATE_FORMAT);
    // let status = 'normal';

    // if (
    //     format(this.deliveryDate, SERVER_DATE_FORMAT) < today &&
    //     [STATUS_OPEN, STATUS_PARTIAL].includes(this.status)
    // ) {
    //     if (this.outShipments && this.outShipments.length === 0) {
    //         status = 'danger';
    //     } else {
    //         status = 'warning';
    //     }
    // }

    // return status;
  }
}

export class SalesOrderStore extends Store {
  static backendResourceName = 'sales_order'
  Model = SalesOrder

  @observable _statusTotals = null
  @observable _requestStatusTotals = null
  @observable _receiptStatusTotals = null

  fetchStatusTotals({ mapParams = (params) => params } = {}) {
    return this.api.get(this.url() + 'status_totals/', mapParams(this.params)).then((res) => {
      this._requestStatusTotals = res.data.request_status
      this._receiptStatusTotals = res.data.receipt_status
      this._statusTotals = res.data.status
    })
  }
}
