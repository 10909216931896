import { observable, action } from 'mobx'
import { Model, Store } from 'store/Base'

import { ProcessVersion } from './ProcessVersion'
import { CapabilityStore } from './Capability'
import { WorkStation } from './WorkStation'
import { ProductionLine } from './ProductionLine'
import { SectionStore } from './Section'
import { BatchType } from './BatchType'
import { Form } from './Form'
import { Script } from './Script'

export const TYPE_PRINT = 'print'
export const TYPE_SCAN = 'form'
export const TYPE_MULTIPLIER = 'multiplier'
export const TYPE_SPLIT = 'split'
export const TYPE_GROW = 'grow'
export const TYPE_SUBPROCESSES = 'subprocesses'
export const TYPES = [TYPE_PRINT, TYPE_SCAN, TYPE_MULTIPLIER, TYPE_SPLIT, TYPE_GROW, TYPE_SUBPROCESSES]

export class Step extends Model {
  static backendResourceName = 'step'
  static ignoreErrors = ['nextStep']

  static TYPE_PRINT = 'print'
  static TYPE_SCAN = 'form'
  static TYPE_MULTIPLIER = 'multiplier'
  static TYPE_SPLIT = 'split'
  static TYPE_GROW = 'grow'
  static TYPE_SUBPROCESSES = 'split'
  static TYPES = ['print', 'form', 'split', 'multiplier', 'grow', 'subprocesses']

  @observable id = null
  @observable type = null
  @observable label = ''
  @observable workMinutes = 5
  @observable workTempoManual = null
  @observable workTempoAutomatic = null
  @observable operatorSupervision = true
  @observable newSerialNumberFormat = [{
    type: 'code',
    alphabet: '0123456789',
    digits: 8,
    expand: true,
  }]

  relations() {
    return {
      processVersion: ProcessVersion,
      nextStep: Step,
      formStep: FormStep,
      splitStep: SplitStep,
      printStep: PrintStep,
      subprocessesStep: SubprocessesStep,
      multiplierStep: MultiplierStep,
      growStep: GrowStep,
      capabilities: CapabilityStore,
      workStation: WorkStation,
      sections: SectionStore,
      subprocessBatchType: BatchType,
    }
  }

  toBackend(...args) {
    const res = super.toBackend(...args)
    if (this.__activeCurrentRelations.includes('nextStep')) {
      if (!this.nextStep.isNew || this.nextStep.hasUserChanges) {
        res.next_step = this.nextStep.getInternalId()
      }
    }
    delete res.type
    delete res.new_serial_number_format
    for (const type of Step.TYPES) {
      if (type !== this.type) {
        res[`${type}_step`] = null
      }
    }
    return res
  }

  @action toBackendAll(...args) {
    const activeCurrentRelations = this.__activeCurrentRelations

    const filterRelations = Step.TYPES.filter((type) => type !== this.type).map((type) => `${type}Step`)

    this.__activeCurrentRelations = activeCurrentRelations.filter((rel) => !filterRelations.includes(rel))

    try {
      return super.toBackendAll(...args)
    } finally {
      this.__activeCurrentRelations = activeCurrentRelations
    }
  }

  perform(data) {
    return this.wrapPendingRequestCount(this.api.post(`${this.url}perform/`, data))
  }
}

export class StepStore extends Store {
  Model = Step
  static backendResourceName = 'step'
}

export class FormStep extends Model {
  static backendResourceName = 'form_step'

  @observable id = null
  @observable icon = 'clipboard list'

  relations() {
    return {
      form: Form,
    }
  }
}

export class PrintStep extends Model {
  static backendResourceName = 'print_step'
  static fileFields = ['documentBackground']

  static TYPE_LABEL = 'label'
  static TYPE_DOCUMENT = 'document'
  static TYPES = ['label', 'document']

  static LABEL_PRINTER_ALTEC = 'altec'
  static LABEL_PRINTER_ZEBRA = 'zebra'
  static LABEL_PRINTERS = ['altec', 'zebra']

  @observable id = null
  @observable type = 'label'
  @observable copies = 1

  @observable labelPrinter = 'altec'
  @observable labelTemplate = ''

  @observable documentBackground = null
  @observable documentOverlays = []

  toBackend(...args) {
    const res = super.toBackend(...args)
    if (this.type !== 'label') {
      res.label_printer = null
      res.label_template = null
    }
    if (this.type !== 'document') {
      res.document_overlays = null
    }
    return res
  }
}

export class SplitStep extends Model {
  static backendResourceName = 'split_step'

  static TYPES = ['scan', 'print', 'article', 'provided']

  @observable id = null
  @observable icon = 'cubes'
  @observable newBatchQuantity = 1
  @observable newBatchVariableQuantity = false
  @observable newBatchVariableQuantityPredetermined = false
  @observable type = 'print'
  @observable printer = 'altec'
  @observable template = '{{serial_number}}'
  @observable copies = 1

  relations() {
    return {
      form: Form,
    }
  }

  toBackend(...args) {
    const data = super.toBackend(...args)
    if (this.type !== 'print') {
      data.printer = null
      data.template = ''
      data.copies = null
    }
    return data
  }
}

export class MultiplierStep extends Model {
  static backendResourceName = 'multiplier_step'

  @observable id = null
  @observable multiplier = 2
}

export class GrowStep extends Model {
  static backendResourceName = 'grow_step'
  static COLORS = ['blue', 'purple', 'red', 'orange', 'green']
  static HEX_COLORS = {
    blue: '#5BA6EF',
    purple: '#AF6FC3',
    red: '#F19084',
    orange: '#F4AD56',
    green: '#5AC361',
  }

  @observable id = null
  @observable days = 0
  @observable color = 'blue'

  relations() {
    return {
      lightScript: Script,
      waterScript: Script,
      environmentScript: Script,
      plantationLine: ProductionLine,
    }
  }
}

export class SubprocessesStep extends Model {
  static backendResourceName = 'subprocesses_step'

  @observable id = null
}
