import React from 'react'
import { observable, action, computed } from 'mobx'
import PropTypes from 'prop-types'
import { TargetBase } from 'spider/semantic-ui/Target'
import { Select } from 'semantic-ui-react'
import { debounce } from 'lodash'
import { ACTION_DELAY } from 'helpers'
import { api } from 'store/Base'

export default class TargetTimezone extends TargetBase {
  static propTypes = {
    ...TargetBase.propTypes,
    value: PropTypes.string,
  }

  constructor(...args) {
    super(...args)
    this.getTimezones = this.getTimezones.bind(this)
    this.clearTimezones = this.clearTimezones.bind(this)
    this.debouncedGetTimezones = debounce(this.getTimezones, ACTION_DELAY)
    this.onSearchChange = this.onSearchChange.bind(this)
  }

  @observable search = ''
  @observable timezones = []

  getTimezones() {
    return api.get('timezone/', { search: this.search }).then((res) => (this.timezones = res))
  }

  clearTimezones() {
    this.timezones = []
  }

  @action onSearchChange(e, { searchQuery }) {
    this.search = searchQuery
    this.debouncedGetTimezones()
  }

  toOption(timezone) {
    return { value: timezone, text: timezone.replace(/_/g, ' ') }
  }

  @computed get options() {
    return (this.value && !this.timezones.includes(this.value) ? [...this.timezones, this.value] : this.timezones).map(
      this.toOption
    )
  }

  @action onChange(e, { value }) {
    this.search = ''
    return super.onChange(value)
  }

  renderContent(props) {
    return (
      <Select
        {...props}
        search={(arr) => arr.slice()}
        value={this.value}
        onChange={this.onChange}
        searchQuery={this.search}
        onSearchChange={this.onSearchChange}
        onOpen={this.getTimezones}
        onClose={this.clearTimezones}
        options={this.options}
      />
    )
  }
}
