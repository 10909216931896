import { observable, computed } from 'mobx'
import { Model, Store } from 'store/Base'
import { ProductionRequest } from './ProductionRequest'

export class ExactShopOrder extends Model {
  static backendResourceName = 'exact_shop_order'
  static idColor = 'red'
  static idIcon = 'exact'

  static STATUS_NAMES = ['open', 'released', 'in_progress', 'finalized', 'complete']

  @observable id = null
  @observable number = null
  @observable uuid = null
  @observable status = 10
  @observable isReleased = false

  getUrl() {
    let shopUrl = this.api.baseUrl + this.url + 'exact/'
    shopUrl = shopUrl.replace(/([^:])(\/\/+)/g, '$1/')

    return shopUrl
  }

  getLink(props = {}) {
    return super.getLink({
      target: '_blank',
      ...props,
    })
  }

  getTarget() {
    return '_blank'
  }

  get statusName() {
    switch (this.status) {
      case 10:
        return this.isReleased ? 'released' : 'open'
      case 20:
        return 'in_progress'
      case 30:
        return 'finalized'
      case 40:
        return 'complete'
      default:
        return this.status
    }
  }

  @computed
  get _labelContent() {
    return this.number
  }

  relations() {
    return {
      productionRequest: ProductionRequest,
    }
  }
}

export class ExactShopOrderStore extends Store {
  Model = ExactShopOrder
  static backendResourceName = 'exact_shop_order'
}
